import Close from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';

interface DialogConfirmationProps {
  open: boolean;
  handleClose?: () => any;
  title?: string | React.ReactNode;
  content?: string | React.ReactNode;
  acceptButtonTitle?: string;
  cancelButtonTitle?: string;
  acceptButtonHandleClick?: any;
  cancelButtonHandleClick?: any;
  type?: 'success' | 'error';
  acceptButtonProps?: any;
  cancelButtonProps?: any;
  loading?: boolean;
  maxWidth?: false | Breakpoint;
  withClose?: boolean;
}

export const DialogConfirmation = ({
  open,
  handleClose,
  title,
  content,
  acceptButtonHandleClick,
  acceptButtonTitle,
  cancelButtonHandleClick,
  cancelButtonTitle,
  acceptButtonProps,
  cancelButtonProps,
  type = 'success',
  loading = false,
  maxWidth = 'md',
  withClose = false,
}: DialogConfirmationProps) => {
  return (
    <Dialog
      PaperProps={{ sx: { overflow: 'unset', position: withClose ? 'relative' : 'unset', width: '100%' } }}
      maxWidth={maxWidth}
      onClose={handleClose}
      open={open}
    >
      <DialogTitle sx={{ p: 3 }}>{title}</DialogTitle>
      {withClose && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      )}
      <DialogContent>
        {typeof content === 'string' ? <DialogContentText>{content}</DialogContentText> : content}
      </DialogContent>
      <DialogActions>
        {cancelButtonTitle && (
          <Button
            onClick={cancelButtonHandleClick}
            disabled={loading}
            color="inherit"
            size="medium"
            variant="text"
            {...cancelButtonProps}
          >
            {cancelButtonTitle}
          </Button>
        )}
        {acceptButtonTitle && (
          <LoadingButton
            onClick={acceptButtonHandleClick}
            loading={loading}
            startIcon={loading && <SaveIcon />}
            loadingPosition={loading ? 'start' : undefined}
            color={type === 'success' ? 'primary' : 'error'}
            variant="contained"
            {...acceptButtonProps}
          >
            {acceptButtonTitle}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
