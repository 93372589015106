import Autorenew from '@mui/icons-material/Autorenew';
import { Badge, Box, Button, CircularProgress, Stack, SxProps, Typography } from '@mui/material';

import CustomPopover, { usePopover } from '@minimalsComponents/custom-popover';

import { IconConfig } from '../../../types';
import { styles, sxPopover, sxRetryBtn, sxRetryContainer, sxTitle } from '../styles';

const RetryIconWithLoader = ({ Icon, isLoading, onClick, sx = {}, retryContent }: IconConfig) => {
  const retryPopover = usePopover();

  if (!Icon) return null;

  return (
    <>
      <Box onClick={!isLoading ? retryPopover.onOpen : undefined} sx={{ ...styles.container, ...sx } as SxProps}>
        <>
          <Badge
            badgeContent={retryContent}
            color="error"
            overlap="circular"
            sx={styles.icon(isLoading ? 'action.disabled' : '')}
          >
            <Icon />
          </Badge>
          {isLoading && <CircularProgress />}
        </>
      </Box>
      <CustomPopover open={retryPopover.open} onClose={retryPopover.onClose} arrow="top-right" sx={sxPopover}>
        <Stack sx={sxRetryContainer}>
          <Stack direction="row" alignItems="center" sx={sxTitle}>
            <Typography variant="h6">Items Failed</Typography>
            <Typography component="span" variant="body1">
              &nbsp;({retryContent})
            </Typography>
          </Stack>
          <Button
            onClick={() => {
              onClick();
              retryPopover.onClose();
            }}
            variant="text"
            startIcon={<Autorenew />}
            sx={sxRetryBtn}
            size="large"
          >
            Retry All
          </Button>
        </Stack>
      </CustomPopover>
    </>
  );
};

export default RetryIconWithLoader;
