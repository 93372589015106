import Settings from '@mui/icons-material/Settings';
import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import { SxProps } from '@mui/system';
import { useMemo, useRef, useState } from 'react';

export type CameraDevice = {
  deviceId: string;
  label?: string;
};

type UserDropdownSelectionProps = {
  cameras: CameraDevice[];
  value?: string;
  onCameraChange: (id: string) => void;
  sx?: SxProps;
};

export const CameraPicker = (props: UserDropdownSelectionProps) => {
  const { cameras, value, onCameraChange, sx } = props;

  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const config = useMemo(() => {
    return cameras.map((camera, index) => ({
      title: camera.label || `Camera ${index + 1}`,
      value: camera.deviceId,
    }));
  }, [cameras]);

  return (
    <>
      <IconButton sx={{ zIndex: 100, ...sx }} size="large" ref={ref} onClick={() => setIsOpen(true)}>
        <Settings sx={{ fontSize: '30px', color: 'white' }} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { minWidth: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {config.map((item, index) => {
          const handleClick = () => {
            onCameraChange(item.value);
            setIsOpen(false);
          };
          return (
            <MenuItem key={index} onClick={handleClick} sx={{ color: 'text.secondary' }}>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{ variant: 'body2', fontWeight: item.value === value ? 'bold' : 'normal' }}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
