import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Box, Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { MotionContainer, varBounce } from '@minimalsComponents/animate';
import { m } from 'framer-motion';

import { sxNotFound } from '../styles';

type Props = {
  onStartReceive: () => void;
};

const NotFound = ({ onStartReceive }: Props) => (
  <Box sx={sxNotFound.box}>
    <MotionContainer sx={sxNotFound.container}>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" paragraph>
          Sorry, session not found!
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={sxNotFound.description}>
          Sorry, we couldn’t find the session you’re looking for. Perhaps you’ve mistyped the URL?
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Box sx={{ my: 10 }}>
          <img src="/assets/illustrations/illustration_404.png" />
        </Box>
      </m.div>

      <Box sx={sxNotFound.buttonBox}>
        <Button
          component={RouterLink}
          sx={{ mr: 2 }}
          to="/mailroom/receive"
          size="large"
          variant="outlined"
          color="inherit"
          startIcon={<ChevronLeft />}
        >
          View Sessions
        </Button>
        <Button onClick={onStartReceive} size="large" variant="contained" color="primary">
          Start Receiving
        </Button>
      </Box>
    </MotionContainer>
  </Box>
);

export default NotFound;
