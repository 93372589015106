import { format } from 'date-fns';
import numeral from 'numeral';

export const formatDateString = (date: string) => {
  return format(new Date(date), 'MMM dd, yyyy');
};

export const formatLongDateString = (date: string) => {
  return format(new Date(date), 'ccc MMM d, yyyy');
};

export const formatTimeString = (date: string) => {
  return format(new Date(date), 'h:mm a');
};

export const formatLongDateTimeString = (date: string) => {
  return format(new Date(date), 'ccc MMM d yyyy, h:mm a');
};

export const formatCurrency = (value: number) => {
  return numeral(value).format('$0,0.00');
};

export const getPlural = (number: number, string: string) => (number === 1 ? string : `${string}s`);
