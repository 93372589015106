import { Box, Skeleton, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/system';
import { FC, useEffect, useState } from 'react';

import { User, UserMin } from '@apiContract';
import { useUserLocation } from 'src/common/hooks/useUserLocation';
import Iconify from 'src/components/iconify';
import { StyledIcon } from 'src/components/snackbar/styles';

import { useRoutesByLocation } from '@common/hooks/useRoutesByLocation';
import { getRoutesList } from '@common/utils/mappers';

import { mdNotification, sxNotification, sxNotificationClose, sxNotificationText } from './styles';

type Props = {
  recipient?: User | UserMin;
};

const RecipientNotification: FC<Props> = ({ recipient }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isVisible, setIsVisible] = useState(true);
  const { location, isLoading: isLocationResponseLoading } = useUserLocation(recipient);
  const routes = useRoutesByLocation(location?.id);

  useEffect(() => {
    if (recipient) {
      setIsVisible(true);
    }
  }, [recipient]);

  const onClose = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <Box sx={isMobile ? sxNotification : mdNotification}>
        <Box>
          <StyledIcon color="success">
            <Iconify icon="eva:checkmark-circle-2-fill" width={24} />
          </StyledIcon>
        </Box>
        <Box sx={sxNotificationText}>
          <Typography variant="body2">
            Assigned to {recipient?.displayName} ({recipient?.userName})
          </Typography>
          {location &&
            (routes.isLoading || isLocationResponseLoading ? (
              <Skeleton variant="text" width="40%" />
            ) : (
              <Box sx={{ display: 'flex' }}>
                <Iconify
                  icon={'material-symbols:mode-of-travel'}
                  width={16}
                  height={16}
                  color={'grey.500'}
                  sx={{ m: '0 4px 0 0' }}
                />
                <Typography variant="caption" sx={{ color: 'grey.500' }}>
                  {getRoutesList(routes?.data)}
                  {location?.name}
                </Typography>
              </Box>
            ))}
        </Box>
        <Box sx={sxNotificationClose}>
          <IconButton size="small" onClick={onClose} sx={{ p: 0.5, ml: 1 }}>
            <Iconify width={16} icon="mingcute:close-line" />
          </IconButton>
        </Box>
      </Box>
    )
  );
};

export default RecipientNotification;
