import { OnFileAddParams } from '../types';
import { type EmptyResultResponse } from '@apiContract';

type Params = {
  url?: string;
  accessToken?: string;
};

type TaskOptions = { timestamp: number | null; taskNumber: number };

const main = () => {
  class FetchError extends Error {
    userMessage: string;
  
    constructor(message: string, data: EmptyResultResponse) {
      super(message);
      this.userMessage = data.userMessage || '';
      Object.setPrototypeOf(this, FetchError.prototype);
    }
  }

  const tasks: ((options: TaskOptions) => Promise<any> | void)[] = [];
  const workersCount = 4;
  let workersNow = 0;
  let params: Params = {};
  let totalTasks = 0;
  let tasksDone = 0;
  let timestamp: number | null = null;
  let taskNumber = 0;

  const runTask = async (task: (options: TaskOptions) => Promise<any> | void) => {
    if (timestamp === null) {
      timestamp = Date.now();
    }
    taskNumber += 1;
    await task({ timestamp, taskNumber });
    const nextTask = tasks.shift();
    if (nextTask) {
      runTask(nextTask);
    } else {
      workersNow -= 1;
      if (workersNow === 0) {
        timestamp = null;
      }
    }
  };

  const addTask = async (task: (options: TaskOptions) => Promise<any> | void) => {
    totalTasks += 1;
    if (workersNow < workersCount) {
      workersNow += 1;
      await runTask(task);
    } else {
      tasks.push(task);
    }
  };

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });

  const dataURLtoBlob = (dataURL: string) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const sendFile = async (
    file: string,
    sessionId: string,
    currentTimestamp: number,
    currentTaskNumber: number,
    fileId: string,
    host: string,
  ) => {
    if (!file || typeof file !== 'string') throw new Error('File not converted');
    const blobContent = file.split(',')?.[1];
    if (!blobContent) throw new Error('File content error');
    const response = await fetch(`${params.url}/api/Piece/form`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${params.accessToken}`,
        'Content-Type': 'application/json',
        'X-Company-Subdomain': host,
      },
      body: JSON.stringify({
        originalFileBase64: file?.split(',')?.[1],
        receiveSessionId: sessionId,
        orderNo: (currentTimestamp || 0) + currentTaskNumber,
      }),
    });
    const piece = await response.json();
    if (!response.ok) {
      throw new FetchError('Piece not created', piece);
    }
    if (!piece.objectData) throw new Error('Piece not created');
    tasksDone += 1;
    postMessage({
      type: 'pieceCreated',
      payload: { fileId, piece: piece.objectData, status: 'completed' },
      stats: { total: totalTasks, done: tasksDone },
    });
  };

  const onFileAdd = async ({ file, sessionId, fileId, resize, host }: OnFileAddParams, options: TaskOptions) => {
    try {
      if (resize === false) {
        await sendFile(file as string, sessionId, options.timestamp as number, options.taskNumber, fileId, host);
        return;
      }
      let originalFileBase64 = file;
      if (typeof originalFileBase64 !== 'string') {
        originalFileBase64 = (await toBase64(file as File)) as string;
      }
      const img = await createImageBitmap(dataURLtoBlob(originalFileBase64));

      const MAX_WIDTH = 2000;
      const MAX_HEIGHT = 2000;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      const canvas = new self.OffscreenCanvas(width, height);
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0, width, height);

      const blobResized = await canvas.convertToBlob();
      const reader = new FileReader();

      const promise = new Promise((resolve, reject) => {
        reader.onloadend = async () => {
          try {
            await sendFile(reader.result as string, sessionId, options.timestamp as number, options.taskNumber, fileId, host);
            resolve(true);
          } catch (err) {
            postMessage({
              type: 'error',
              payload: { fileId, piece: null, status: 'failed' },
              stats: { total: totalTasks, done: tasksDone },
            });
  
            if (err instanceof FetchError && err.userMessage)  {
              postMessage({
                type: 'fetchError',
                payload: err.userMessage,
              });
            }
            console.error(err);
            reject();
          }
        };
      });

      reader.readAsDataURL(blobResized);
      await promise;
    } catch (error) {
      postMessage({
        type: 'error',
        payload: { fileId, piece: null, status: 'failed' },
        stats: { total: totalTasks, done: tasksDone },
      });
      console.error(error);
    }
  };

  const stop = () => {
    while (tasks.length > 0) {
      tasks.pop();
    }
  };

  self.addEventListener('message', (e) => {
    switch (e.data.type) {
      case 'addFile':
        if (Array.isArray(e.data.payload)) {
          e.data.payload.forEach((payload: OnFileAddParams) => {
            addTask((options: TaskOptions) => onFileAdd(payload, options));
          });
        } else {
          addTask((options: TaskOptions) => onFileAdd(e.data.payload, options));
        }
        break;
      case 'setup':
        params = e.data.payload;
        break;
      case 'stop':
        stop();
        break;
      case 'getStats':
        postMessage({ type: 'getStats', stats: { total: totalTasks, done: tasksDone } });
        break;
      default:
        break;
    }
  });
};

export default main;
