import { customShadows } from 'src/theme/custom-shadows';

export const sxNotification = {
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  right: 16,
  top: 16,
  maxWidth: 500,
  backgroundColor: 'grey.800',
  marginLeft: '16px',
  padding: 1,
  borderRadius: 1,
  boxShadow: customShadows('dark').z8,
};

export const mdNotification = {
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  right: 24,
  top: 24,
  maxWidth: 500,
  backgroundColor: 'grey.800',
  padding: 1,
  borderRadius: 1,
  boxShadow: customShadows('dark').z8,
};

export const sxNotificationText = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  minWidth: 200,
};

export const sxNotificationClose = {
  display: 'flex',
  alignItems: 'center',
};
