import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useDropzone } from 'react-dropzone';

import { sxDropBox, sxList, sxUpload } from '../../styles';
import { UploadProps } from '../../types';
import ErrorsRejectionFiles from './ErrorsRejectionFiles';
import Placeholder from './Placeholder';
import PreviewMultiFile from './PreviewMultiFile';

export default function Upload({ disabled, error, onDrop, files, onRemove, ...other }: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    disabled,
    onDrop,
    accept: { 'image/*': [] },
    maxSize: 31457280,
    useFsAccessApi: false,
    ...other,
  });

  const hasFiles = !!files && !!files.length;

  const hasError = isDragReject || !!error;

  return (
    <Box sx={sxUpload}>
      <Box {...getRootProps()} sx={sxDropBox(isDragActive, !!disabled, hasError)}>
        <input {...getInputProps()} />

        <Placeholder />
      </Box>

      {error && (
        <Typography variant="body2" sx={{ color: 'error.main' }}>
          {error}
        </Typography>
      )}

      <ErrorsRejectionFiles fileRejections={fileRejections} />

      {hasFiles && (
        <Box sx={(theme) => sxList(theme, files.length)}>
          <PreviewMultiFile files={files} onRemove={onRemove} />
        </Box>
      )}
    </Box>
  );
}
