import CameraAltRounded from '@mui/icons-material/CameraAltRounded';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LinkedCamera from '@mui/icons-material/LinkedCamera';
import UploadRounded from '@mui/icons-material/UploadRounded';
import { LoadingButton } from '@mui/lab';
import { MenuItem, Popover, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';

import { Steps } from '../types';

type Props = {
  type: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  label: string;
  isMobile?: boolean;
  disabled?: boolean;
  onClick: (param: Steps) => void;
};

const Buttons = ({ type, label, isMobile, disabled, onClick }: Props) => {
  const [open, setOpen] = useState<HTMLButtonElement | null>(null);

  const menu = [
    {
      title: 'Single',
      icon: <CameraAltRounded />,
      onClick: () => onClick(Steps.Photo),
    },
    {
      title: 'Automated',
      icon: <LinkedCamera />,
      disabled: true,
    },
    {
      title: 'Upload Images',
      icon: <UploadRounded />,
      onClick: () => onClick(Steps.Images),
    },
  ];

  return (
    <Box sx={{ position: 'relative', width: isMobile && !disabled ? '49%' : 'auto', height: '100%' }}>
      <LoadingButton
        variant={type === 'primary' ? 'contained' : 'outlined'}
        onClick={(event) => setOpen(event.currentTarget as HTMLButtonElement)}
        key={label}
        color={type}
        size={isMobile ? 'large' : 'medium'}
        sx={{ width: isMobile ? '100%' : 'auto', height: '100%' }}
        disabled={disabled}
        endIcon={!isMobile && (open ? <ExpandLess /> : <ExpandMore />)}
      >
        {label}
      </LoadingButton>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {menu.map((item) => (
          <span key={item.title}>
            <MenuItem
              onClick={() => {
                item.onClick?.();
                setOpen(null);
              }}
              disabled={item.disabled}
            >
              {item.icon || null}
              <Typography variant="body2" sx={{ ml: 2 }}>
                {item.title}
              </Typography>
            </MenuItem>
          </span>
        ))}
      </Popover>
    </Box>
  );
};

const ContinueCapture = (props: Props) => {
  return props.disabled ? (
    <Tooltip
      sx={{ width: props.isMobile ? '49%' : 'auto' }}
      title={
        <Box sx={{ textAlign: 'center' }}>
          This session is closed. <br /> All items are already sent.
        </Box>
      }
    >
      <Box>
        <Buttons {...props} />
      </Box>
    </Tooltip>
  ) : (
    <Buttons {...props} />
  );
};

export default ContinueCapture;
