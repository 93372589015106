/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActiveSpan {
  /** @format int32 */
  id: number;
  /**
   *
   *
   * 0 = Sunday
   *
   * 1 = Monday
   *
   * 2 = Tuesday
   *
   * 3 = Wednesday
   *
   * 4 = Thursday
   *
   * 5 = Friday
   *
   * 6 = Saturday
   */
  dayOfWeek: DayOfWeek;
  /** @format int32 */
  open: number;
  /** @format int32 */
  close: number;
}

export interface Address {
  /** @format int32 */
  id: number;
  /**
   *
   *
   * 0 = Business
   *
   * 1 = Billing
   *
   * 2 = Contact
   *
   * 3 = Shipping
   *
   * 4 = Recipient
   *
   * 5 = Mailing
   */
  addressType: AddressType;
  default: boolean;
  /** @minLength 1 */
  description: string;
  /** @minLength 1 */
  countryCode: string;
  /** @minLength 1 */
  administrativeArea: string;
  /** @minLength 1 */
  locality: string;
  /** @minLength 1 */
  postalCode: string;
  /** @minLength 1 */
  addressLine1: string;
  /** @minLength 1 */
  addressLine2: string;
}

export interface AddressArrayResponse {
  userMessage?: string | null;
  objectData: Address[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface AddressRequest {
  /** @format int32 */
  id?: number;
  /**
   *
   *
   * 0 = Business
   *
   * 1 = Billing
   *
   * 2 = Contact
   *
   * 3 = Shipping
   *
   * 4 = Recipient
   *
   * 5 = Mailing
   */
  addressType?: AddressType;
  default?: boolean;
  description?: string | null;
  countryCode?: string | null;
  administrativeArea?: string | null;
  locality?: string | null;
  postalCode?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
}

export interface AddressResponse {
  userMessage?: string | null;
  objectData: Address;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

/**
 *
 *
 * 0 = Business
 *
 * 1 = Billing
 *
 * 2 = Contact
 *
 * 3 = Shipping
 *
 * 4 = Recipient
 *
 * 5 = Mailing
 * @format int32
 */
export enum AddressType {
  Business = 0,
  Billing = 1,
  Contact = 2,
  Shipping = 3,
  Recipient = 4,
  Mailing = 5,
}

/**
 *
 *
 * 0 = Recipient
 *
 * 1 = Sender
 *
 * 2 = TrackingNumber
 *
 * 3 = Carrier
 *
 * 4 = PackageWeight
 *
 * 5 = PackageSize
 *
 * 6 = ReferenceNumber
 *
 * 7 = PurchaseOrderNumber
 *
 * 8 = InvoiceNumber
 * @format int32
 */
export enum AnnotationType {
  Recipient = 0,
  Sender = 1,
  TrackingNumber = 2,
  Carrier = 3,
  PackageWeight = 4,
  PackageSize = 5,
  ReferenceNumber = 6,
  PurchaseOrderNumber = 7,
  InvoiceNumber = 8,
}

export interface AssistantRequest {
  id?: string | null;
}

/**
 *
 *
 * 0 = AllRecipients
 *
 * 1 = SpecificRecipients
 * @format int32
 */
export enum AutomationSetType {
  AllRecipients = 0,
  SpecificRecipients = 1,
}

export interface BooleanResponse {
  userMessage?: string | null;
  objectData: boolean;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface BoundingBox {
  /** @format int32 */
  id: number;
  /** @format int32 */
  topLeftX: number;
  /** @format int32 */
  topLeftY: number;
  /** @format int32 */
  topRightX: number;
  /** @format int32 */
  topRightY: number;
  /** @format int32 */
  bottomLeftX: number;
  /** @format int32 */
  bottomLeftY: number;
  /** @format int32 */
  bottomRightX: number;
  /** @format int32 */
  bottomRightY: number;
}

/**
 *
 *
 * 0 = Corporate
 *
 * 1 = University
 * @format int32
 */
export enum BusinessDomain {
  Corporate = 0,
  University = 1,
}

/**
 *
 *
 * 1 = Single
 *
 * 2 = Automated
 *
 * 3 = Images
 * @format int32
 */
export enum CaptureMethod {
  Single = 1,
  Automated = 2,
  Images = 3,
}

export interface Card {
  /** @minLength 1 */
  paymentMethodId: string;
  /** @minLength 1 */
  last4: string;
  /** @minLength 1 */
  brand: string;
  isDefault: boolean;
}

export interface CardArrayResponse {
  userMessage?: string | null;
  objectData: Card[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface Company {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  email: string;
  phone?: string | null;
  /** @minLength 1 */
  subdomain: string;
  addresses: Address[];
}

export interface CompanyArrayResponse {
  userMessage?: string | null;
  objectData: Company[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface CompanyPagedList {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  totalPages: number;
  items?: Company[] | null;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface CompanyPagedListResponse {
  userMessage?: string | null;
  objectData: CompanyPagedList;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface CompanyResponse {
  userMessage?: string | null;
  objectData: Company;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface Container {
  /** @format int32 */
  id: number;
  containerType: ContainerType;
  containerNumber?: string | null;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  updatedDate: string;
  lastPieceAssets: PieceAsset[];
  /** @format int32 */
  allCount: number;
  /** @format int32 */
  sentCount: number;
  /** @format int32 */
  unsentCount: number;
  /** @minLength 1 */
  userId: string;
  /** @minLength 1 */
  sortOrder: string;
  /** @format int64 */
  nextOrderNo: number;
  /** @minLength 1 */
  containerTitleFormatted: string;
  /** @minLength 1 */
  containerCardTitleFormatted: string;
  /** @minLength 1 */
  containerDateFormatted: string;
}

export interface ContainerArrayResponse {
  userMessage?: string | null;
  objectData: Container[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

/**
 *
 *
 * 0 = All
 *
 * 1 = WithUnsentItems
 *
 * 2 = WithoutUnsentItems
 * @format int32
 */
export enum ContainerFilterStorageGroups {
  All = 0,
  WithUnsentItems = 1,
  WithoutUnsentItems = 2,
}

export interface ContainerPagedList {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  totalPages: number;
  items?: Container[] | null;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface ContainerPagedListResponse {
  userMessage?: string | null;
  objectData: ContainerPagedList;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface ContainerRequest {
  /** @format int32 */
  id?: number | null;
  /** @format date-time */
  createdDate?: string | null;
  /** @format int32 */
  containerTypeId?: number;
  containerNumber?: string | null;
  sortOrder?: string | null;
  userId?: string | null;
}

export interface ContainerResponse {
  userMessage?: string | null;
  objectData: Container;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

/**
 *
 *
 * 0 = None
 *
 * 1 = AddToBack
 *
 * 2 = AddToFront
 * @format int32
 */
export enum ContainerSortOrder {
  None = 0,
  AddToBack = 1,
  AddToFront = 2,
}

export interface ContainerType {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  workflow: string;
  /** @minLength 1 */
  containerUiLabel: string;
}

export interface ContainerTypeArrayResponse {
  userMessage?: string | null;
  objectData: ContainerType[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface CreateActiveSpanRequest {
  /**
   *
   *
   * 0 = Sunday
   *
   * 1 = Monday
   *
   * 2 = Tuesday
   *
   * 3 = Wednesday
   *
   * 4 = Thursday
   *
   * 5 = Friday
   *
   * 6 = Saturday
   */
  dayOfWeek: DayOfWeek;
  /** @format int32 */
  open: number;
  /** @format int32 */
  close: number;
}

export interface CreateBoundingBoxRequest {
  /** @format int32 */
  topLeftX?: number;
  /** @format int32 */
  topLeftY?: number;
  /** @format int32 */
  topRightX?: number;
  /** @format int32 */
  topRightY?: number;
  /** @format int32 */
  bottomLeftX?: number;
  /** @format int32 */
  bottomLeftY?: number;
  /** @format int32 */
  bottomRightX?: number;
  /** @format int32 */
  bottomRightY?: number;
}

export interface CreateCompanyRequest {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  email: string;
  phone?: string | null;
  /** @minLength 1 */
  subdomain: string;
  addresses?: AddressRequest[] | null;
}

export interface CreateDeliveryRouteRequest {
  /** @minLength 1 */
  name: string;
  locations?: LocationRequest[] | null;
}

export interface CreateHoursOfOperationRequest {
  /** @minLength 1 */
  timeZoneInfoId: string;
  activeSpans?: CreateActiveSpanRequest[] | null;
}

export interface CreateLocationRequest {
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  sortOrder?: number;
  userIds?: string[] | null;
}

export interface CreateMailroomRequest {
  /** @minLength 1 */
  name: string;
  address?: AddressRequest;
  email?: string | null;
  /** @format date-time */
  openTime?: string | null;
  /** @format date-time */
  closeTime?: string | null;
  hoursOfOperation?: CreateHoursOfOperationRequest;
}

export interface CreatePieceAnnotationRequest {
  /** @format int32 */
  pieceId?: number;
  /**
   *
   *
   * 0 = Recipient
   *
   * 1 = Sender
   *
   * 2 = TrackingNumber
   *
   * 3 = Carrier
   *
   * 4 = PackageWeight
   *
   * 5 = PackageSize
   *
   * 6 = ReferenceNumber
   *
   * 7 = PurchaseOrderNumber
   *
   * 8 = InvoiceNumber
   */
  annotationType?: AnnotationType;
  textValue?: string | null;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  updated?: string;
  boundingBox?: CreateBoundingBoxRequest;
}

export interface CreatePieceCommentRequest {
  /** @format int32 */
  parentCommentId?: number | null;
  text?: string | null;
  /** @format int32 */
  pieceId?: number;
  notifyMailroom?: boolean;
}

export interface CreatePieceCommentsRequest {
  /** @minLength 1 */
  text: string;
  notifyMailroom?: boolean;
  pieceIds: number[];
}

export interface CreatePieceDocRequest {
  /** @format int32 */
  pieceId?: number;
}

export interface CreatePieceFormRequest {
  originalFileBase64?: string | null;
  /** @format int64 */
  orderNo?: number | null;
  /** @format uuid */
  receiveSessionId: string;
}

export interface CreatePiecePageRequest {
  /** @format int32 */
  pieceDocId: number;
  /**
   * @minLength 1
   * @maxLength 255
   */
  originalKey: string;
  /** @maxLength 255 */
  croppedKey?: string | null;
  /** @maxLength 255 */
  thumbnailKey?: string | null;
  /** @format int32 */
  sortOrder?: number;
}

export interface CreatePieceRequest {
  /**
   * @minLength 1
   * @maxLength 255
   */
  imageKey: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  thumbnailKey: string;
  /** @maxLength 255 */
  originalKey?: string | null;
  pieceLocation?: PieceLocationRequest;
  /**
   *
   *
   * 1 = Mail
   *
   * 2 = Package
   */
  pieceType?: PieceType;
  /** @format uuid */
  receiveSessionId?: string | null;
}

export interface CreateProfileRequest {
  userName?: string | null;
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  phoneNumber?: string | null;
}

export interface CreateUserRequest {
  userName?: string | null;
  /** @minLength 1 */
  email: string;
  phoneNumber?: string | null;
  supervisorId?: string | null;
  addresses?: AddressRequest[] | null;
  roles: string[];
  profileImageUrl?: string | null;
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  quotaExceeded?: boolean;
  assistants?: AssistantRequest[] | null;
  /** @format int32 */
  locationId?: number | null;
  sendPasswordEmail?: boolean | null;
}

export interface Dashboard {
  /** @minLength 1 */
  timeZone: string;
  /** @format float */
  trend: number;
  data: DashboardItem[];
}

export interface DashboardItem {
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  mail: number;
  /** @format int32 */
  package: number;
}

export interface DashboardResponse {
  userMessage?: string | null;
  objectData: Dashboard;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

/**
 *
 *
 * 0 = Sunday
 *
 * 1 = Monday
 *
 * 2 = Tuesday
 *
 * 3 = Wednesday
 *
 * 4 = Thursday
 *
 * 5 = Friday
 *
 * 6 = Saturday
 * @format int32
 */
export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export interface DeliveryRoute {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  name: string;
  locations?: Location[] | null;
  createdBy: UserMin;
  /** @format date-time */
  createdOn: string;
  updatedBy: UserMin;
  /** @format date-time */
  updatedOn: string;
}

export interface DeliveryRoutePagedList {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  totalPages: number;
  items?: DeliveryRoute[] | null;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface DeliveryRoutePagedListResponse {
  userMessage?: string | null;
  objectData: DeliveryRoutePagedList;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface DeliveryRouteResponse {
  userMessage?: string | null;
  objectData: DeliveryRoute;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export type EmptyResult = object;

export interface EmptyResultResponse {
  userMessage?: string | null;
  objectData: EmptyResult;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface Error {
  message?: string | null;
  code?: string | null;
  errorType?: string | null;
}

export interface HoursOfOperation {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  timeZoneInfoId: string;
  activeSpans: ActiveSpan[];
}

/**
 *
 *
 * 0 = Unchanged
 *
 * 1 = Added
 *
 * 2 = Activated
 *
 * 3 = Updated
 *
 * 4 = Inactivated
 *
 * 5 = Error
 * @format int32
 */
export enum ImportState {
  Unchanged = 0,
  Added = 1,
  Activated = 2,
  Updated = 3,
  Inactivated = 4,
  Error = 5,
}

export interface ImportUserRequest {
  userName?: string | null;
  /** @minLength 1 */
  email: string;
  phoneNumber?: string | null;
  supervisorEmail?: string | null;
  assistantEmail?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  /**
   *
   *
   * 0 = Deleted
   *
   * 1 = Active
   */
  status?: Status;
  location?: string | null;
}

export interface ImportUsersRequest {
  inactivateAllExceptImported?: boolean;
  cleanUserNameForAllExceptImported?: boolean;
  propsListForUpdate?: string[] | null;
  usersRequest?: ImportUserRequest[] | null;
  sendPasswordEmail?: boolean | null;
}

export interface Int32ArrayResponse {
  userMessage?: string | null;
  objectData: number[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface Invoice {
  /** @format int32 */
  id: number;
  /** @format int32 */
  companyId: number;
  /** @minLength 1 */
  invoiceUrl: string;
  /** @format date-time */
  created: string;
  /** @format double */
  amount: number;
  /** @minLength 1 */
  currency: string;
  success: boolean;
}

export interface InvoiceArrayResponse {
  userMessage?: string | null;
  objectData: Invoice[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface InvoiceResponse {
  userMessage?: string | null;
  objectData: Invoice;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface Location {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  sortOrder: number;
  children?: Location[] | null;
  createdBy: UserMin;
  /** @format date-time */
  createdOn: string;
  updatedBy: UserMin;
  /** @format date-time */
  updatedOn: string;
  userLocations?: UserLocation[] | null;
}

export interface LocationArrayResponse {
  userMessage?: string | null;
  objectData: Location[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface LocationPagedList {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  totalPages: number;
  items?: Location[] | null;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface LocationPagedListResponse {
  userMessage?: string | null;
  objectData: LocationPagedList;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface LocationRequest {
  /** @format int32 */
  id: number;
  /** @format int32 */
  sortOrder?: number;
}

export interface LocationResponse {
  userMessage?: string | null;
  objectData: Location;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

/**
 *
 *
 * 0 = Add
 *
 * 1 = Remove
 * @format int32
 */
export enum LocationUserOperationType {
  Add = 0,
  Remove = 1,
}

export interface Mailroom {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  name: string;
  address: Address;
  /** @minLength 1 */
  email: string;
  /** @format date-time */
  openTime: string;
  /** @format date-time */
  closeTime: string;
  hoursOfOperation: HoursOfOperation;
}

export interface MailroomArrayResponse {
  userMessage?: string | null;
  objectData: Mailroom[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface MailroomResponse {
  userMessage?: string | null;
  objectData: Mailroom;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PaymentError {
  error?: string | null;
  paymentMethod?: Card;
}

/**
 *
 *
 * 0 = Months
 *
 * 1 = Years
 * @format int32
 */
export enum PaymentInterval {
  Months = 0,
  Years = 1,
}

/**
 *
 *
 * 0 = Week
 *
 * 1 = Month
 *
 * 2 = Year
 *
 * 3 = Today
 *
 * 4 = NotToday
 * @format int32
 */
export enum PeriodType {
  Week = 0,
  Month = 1,
  Year = 2,
  Today = 3,
  NotToday = 4,
}

export interface PickupSession {
  /** @format uuid */
  id?: string;
}

export interface PickupSessionResponse {
  userMessage?: string | null;
  objectData: PickupSession;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface Piece {
  /** @format int32 */
  id: number;
  pieceAssets: PieceAsset[];
  /** @format date-time */
  created: string;
  company: Company;
  user: UserMin;
  recipient?: UserMin;
  /**
   *
   *
   * 0 = NA
   *
   * 101 = Create
   *
   * 102 = Assign
   *
   * 103 = Store
   *
   * 104 = CropImage
   *
   * 201 = Submit
   *
   * 202 = RequestForward
   *
   * 203 = Move
   *
   * 301 = RequestRecycle
   *
   * 302 = RequestOpenAndScan
   *
   * 303 = RequestMail
   *
   * 304 = RequestPickUp
   *
   * 305 = RequestShred
   *
   * 306 = RequestDelivery
   *
   * 307 = RequestLockerPickUp
   *
   * 401 = FinishRecycle
   *
   * 402 = FinishOpenAndScan
   *
   * 403 = FinishMail
   *
   * 404 = FinishPickUp
   *
   * 405 = FinishShred
   *
   * 406 = FinishPickUpWithSignature
   *
   * 407 = FinishPickUpWithQrCode
   *
   * 408 = FinishDelivery
   *
   * 409 = FinishDeliveryWithSignature
   *
   * 410 = FinishDeliveryWithQrCode
   *
   * 411 = FinishPickUpWithPinCode
   *
   * 412 = FinishPickUpUnload
   *
   * 501 = RemindAction
   *
   * 502 = EscalateAction
   *
   * 503 = RemindReview
   *
   * 504 = EscalateReview
   *
   * 505 = RemindLockerPickup
   *
   * 506 = LockerExp
   *
   * 601 = ViewDoc
   *
   * 602 = DownloadDoc
   *
   * 603 = ScheduleDocumentNotViewedEscalation
   *
   * 604 = SchedulePieceNoActivityEscalation
   *
   * 605 = ScheduleDocumentNotViewed
   *
   * 606 = SchedulePieceNoActivity
   *
   * 607 = ScheduleLockersPickupReminder
   *
   * 608 = AutoCroppingComplete
   *
   * 609 = BarcodeDetectionComplete
   *
   * 901 = Archive
   *
   * -1 = Delete
   */
  lifecycleStage: PieceAction;
  users: PieceUser[];
  /**
   *
   *
   * 1 = Mail
   *
   * 2 = Package
   */
  pieceType: PieceType;
  /**
   *
   *
   * 1 = DateReceived
   *
   * 2 = HighDensityMail
   *
   * 3 = MailBox
   *
   * 4 = Location
   *
   * 5 = Carrier
   *
   * 6 = Lockers
   */
  workflow: Workflow;
  events: PieceEvent[];
  /** @format int32 */
  commentsCount: number;
  pieceDoc?: PieceDoc;
  recipientAutoDetection?: RecipientAutoDetection;
  trackingNumber?: string | null;
  pieceLocation?: PieceLocation;
  /** @format date-time */
  pickupDate?: string | null;
  /** @format date-time */
  actionRequiredBy?: string | null;
  mailTo?: Address;
  /** @format int32 */
  mailroomId: number;
  available: boolean;
  scanned: boolean;
}

/**
 *
 *
 * 0 = NA
 *
 * 101 = Create
 *
 * 102 = Assign
 *
 * 103 = Store
 *
 * 104 = CropImage
 *
 * 201 = Submit
 *
 * 202 = RequestForward
 *
 * 203 = Move
 *
 * 301 = RequestRecycle
 *
 * 302 = RequestOpenAndScan
 *
 * 303 = RequestMail
 *
 * 304 = RequestPickUp
 *
 * 305 = RequestShred
 *
 * 306 = RequestDelivery
 *
 * 307 = RequestLockerPickUp
 *
 * 401 = FinishRecycle
 *
 * 402 = FinishOpenAndScan
 *
 * 403 = FinishMail
 *
 * 404 = FinishPickUp
 *
 * 405 = FinishShred
 *
 * 406 = FinishPickUpWithSignature
 *
 * 407 = FinishPickUpWithQrCode
 *
 * 408 = FinishDelivery
 *
 * 409 = FinishDeliveryWithSignature
 *
 * 410 = FinishDeliveryWithQrCode
 *
 * 411 = FinishPickUpWithPinCode
 *
 * 412 = FinishPickUpUnload
 *
 * 501 = RemindAction
 *
 * 502 = EscalateAction
 *
 * 503 = RemindReview
 *
 * 504 = EscalateReview
 *
 * 505 = RemindLockerPickup
 *
 * 506 = LockerExp
 *
 * 601 = ViewDoc
 *
 * 602 = DownloadDoc
 *
 * 603 = ScheduleDocumentNotViewedEscalation
 *
 * 604 = SchedulePieceNoActivityEscalation
 *
 * 605 = ScheduleDocumentNotViewed
 *
 * 606 = SchedulePieceNoActivity
 *
 * 607 = ScheduleLockersPickupReminder
 *
 * 608 = AutoCroppingComplete
 *
 * 609 = BarcodeDetectionComplete
 *
 * 901 = Archive
 *
 * -1 = Delete
 * @format int32
 */
export enum PieceAction {
  NA = 0,
  Create = 101,
  Assign = 102,
  Store = 103,
  CropImage = 104,
  Submit = 201,
  RequestForward = 202,
  Move = 203,
  RequestRecycle = 301,
  RequestOpenAndScan = 302,
  RequestMail = 303,
  RequestPickUp = 304,
  RequestShred = 305,
  RequestDelivery = 306,
  RequestLockerPickUp = 307,
  FinishRecycle = 401,
  FinishOpenAndScan = 402,
  FinishMail = 403,
  FinishPickUp = 404,
  FinishShred = 405,
  FinishPickUpWithSignature = 406,
  FinishPickUpWithQrCode = 407,
  FinishDelivery = 408,
  FinishDeliveryWithSignature = 409,
  FinishDeliveryWithQrCode = 410,
  FinishPickUpWithPinCode = 411,
  FinishPickUpUnload = 412,
  RemindAction = 501,
  EscalateAction = 502,
  RemindReview = 503,
  EscalateReview = 504,
  RemindLockerPickup = 505,
  LockerExp = 506,
  ViewDoc = 601,
  DownloadDoc = 602,
  ScheduleDocumentNotViewedEscalation = 603,
  SchedulePieceNoActivityEscalation = 604,
  ScheduleDocumentNotViewed = 605,
  SchedulePieceNoActivity = 606,
  ScheduleLockersPickupReminder = 607,
  AutoCroppingComplete = 608,
  BarcodeDetectionComplete = 609,
  Archive = 901,
  Delete = -1,
}

/**
 *
 *
 * 0 = ChainOfCustody
 *
 * 1 = System
 * @format int32
 */
export enum PieceActionType {
  ChainOfCustody = 0,
  System = 1,
}

export interface PieceActiveCounters {
  /** @format int32 */
  unassignedCount: number;
  /** @format int32 */
  unstoredCount: number;
  /** @format int32 */
  unsentCount: number;
}

export interface PieceAnnotation {
  /** @format int32 */
  id: number;
  /** @format int32 */
  pieceId: number;
  /**
   *
   *
   * 0 = Recipient
   *
   * 1 = Sender
   *
   * 2 = TrackingNumber
   *
   * 3 = Carrier
   *
   * 4 = PackageWeight
   *
   * 5 = PackageSize
   *
   * 6 = ReferenceNumber
   *
   * 7 = PurchaseOrderNumber
   *
   * 8 = InvoiceNumber
   */
  annotationType: AnnotationType;
  textValue?: string | null;
  boundingBox?: BoundingBox;
  pieceAnnotationsRecipient?: PieceAnnotationsRecipient;
  pieceAnnotationsTrackingNumber?: PieceAnnotationsTrackingNumber;
}

export interface PieceAnnotationArrayResponse {
  userMessage?: string | null;
  objectData: PieceAnnotation[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceAnnotationPagedList {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  totalPages: number;
  items?: PieceAnnotation[] | null;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface PieceAnnotationPagedListResponse {
  userMessage?: string | null;
  objectData: PieceAnnotationPagedList;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceAnnotationResponse {
  userMessage?: string | null;
  objectData: PieceAnnotation;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceAnnotations {
  /** @format int32 */
  pieceId: number;
  annotations: PieceAnnotation[];
}

export interface PieceAnnotationsRecipient {
  /** @format int32 */
  id: number;
  recipientId?: string | null;
  recipientName?: string | null;
  recipientAddress?: string | null;
  recipientSearchString?: string | null;
  /**
   *
   *
   * 0 = Identified
   *
   * 1 = IdentifiedBasedOnPreviousAssignment
   *
   * 2 = IdentifiedVerificationRequired
   *
   * 3 = IdentifiedMultipleMatches
   *
   * 4 = IdentifiedAndNotExist
   *
   * 5 = Failed
   */
  status: RecipientAutoDetectionStatus;
  recipient?: User;
}

export interface PieceAnnotationsTrackingNumber {
  /** @format int32 */
  id: number;
  /**
   *
   *
   * 0 = Valid
   *
   * 1 = BadFormat
   *
   * 2 = Conflict
   */
  validationStatus: TrackingNumberValidationStatus;
  /**
   *
   *
   * 0 = None
   *
   * 1 = Sent
   *
   * 2 = Confirmed
   *
   * 3 = Failed
   *
   * 4 = Canceled
   */
  announcementStatus: TrackingNumberAnnouncementStatus;
}

export interface PieceArrayResponse {
  userMessage?: string | null;
  objectData: Piece[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceAsset {
  /** @format int32 */
  id: number;
  /**
   *
   *
   * 1 = Original
   *
   * 2 = AutoCropped
   *
   * 3 = Cropped
   *
   * 5 = PickupSignature
   *
   * 6 = MlImage
   *
   * 7 = PdfDocument
   */
  pieceAssetType: PieceAssetType;
  /**
   *
   *
   * 1 = Large
   *
   * 2 = Medium
   *
   * 3 = Small
   */
  pieceAssetSize: PieceAssetSize;
  key?: string | null;
  url?: string | null;
}

/**
 *
 *
 * 1 = Large
 *
 * 2 = Medium
 *
 * 3 = Small
 * @format int32
 */
export enum PieceAssetSize {
  Large = 1,
  Medium = 2,
  Small = 3,
}

/**
 *
 *
 * 1 = Original
 *
 * 2 = AutoCropped
 *
 * 3 = Cropped
 *
 * 5 = PickupSignature
 *
 * 6 = MlImage
 *
 * 7 = PdfDocument
 * @format int32
 */
export enum PieceAssetType {
  Original = 1,
  AutoCropped = 2,
  Cropped = 3,
  PickupSignature = 5,
  MlImage = 6,
  PdfDocument = 7,
}

export interface PieceAssetUpload {
  /**
   *
   *
   * 1 = Original
   *
   * 2 = AutoCropped
   *
   * 3 = Cropped
   *
   * 5 = PickupSignature
   *
   * 6 = MlImage
   *
   * 7 = PdfDocument
   */
  pieceAssetType: PieceAssetType;
  /**
   *
   *
   * 1 = Large
   *
   * 2 = Medium
   *
   * 3 = Small
   */
  pieceAssetSize: PieceAssetSize;
  /** @minLength 1 */
  key: string;
  /** @minLength 1 */
  url: string;
}

/**
 *
 *
 * 0 = All
 *
 * 1 = Assigned
 *
 * 2 = Unassigned
 * @format int32
 */
export enum PieceAssignmentStatusFilter {
  All = 0,
  Assigned = 1,
  Unassigned = 2,
}

export interface PieceAutoAction {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  name: string;
  pieceAutoActionUsers: string[];
  /**
   *
   *
   * 0 = NA
   *
   * 101 = Create
   *
   * 102 = Assign
   *
   * 103 = Store
   *
   * 104 = CropImage
   *
   * 201 = Submit
   *
   * 202 = RequestForward
   *
   * 203 = Move
   *
   * 301 = RequestRecycle
   *
   * 302 = RequestOpenAndScan
   *
   * 303 = RequestMail
   *
   * 304 = RequestPickUp
   *
   * 305 = RequestShred
   *
   * 306 = RequestDelivery
   *
   * 307 = RequestLockerPickUp
   *
   * 401 = FinishRecycle
   *
   * 402 = FinishOpenAndScan
   *
   * 403 = FinishMail
   *
   * 404 = FinishPickUp
   *
   * 405 = FinishShred
   *
   * 406 = FinishPickUpWithSignature
   *
   * 407 = FinishPickUpWithQrCode
   *
   * 408 = FinishDelivery
   *
   * 409 = FinishDeliveryWithSignature
   *
   * 410 = FinishDeliveryWithQrCode
   *
   * 411 = FinishPickUpWithPinCode
   *
   * 412 = FinishPickUpUnload
   *
   * 501 = RemindAction
   *
   * 502 = EscalateAction
   *
   * 503 = RemindReview
   *
   * 504 = EscalateReview
   *
   * 505 = RemindLockerPickup
   *
   * 506 = LockerExp
   *
   * 601 = ViewDoc
   *
   * 602 = DownloadDoc
   *
   * 603 = ScheduleDocumentNotViewedEscalation
   *
   * 604 = SchedulePieceNoActivityEscalation
   *
   * 605 = ScheduleDocumentNotViewed
   *
   * 606 = SchedulePieceNoActivity
   *
   * 607 = ScheduleLockersPickupReminder
   *
   * 608 = AutoCroppingComplete
   *
   * 609 = BarcodeDetectionComplete
   *
   * 901 = Archive
   *
   * -1 = Delete
   */
  action: PieceAction;
  payLoad?: string | null;
  /**
   *
   *
   * 1 = Mail
   *
   * 2 = Package
   */
  pieceType: PieceType;
  /**
   *
   *
   * 0 = AllRecipients
   *
   * 1 = SpecificRecipients
   */
  automationSetType: AutomationSetType;
  /** @format date-time */
  createdDate: string;
}

export interface PieceAutoActionArrayResponse {
  userMessage?: string | null;
  objectData: PieceAutoAction[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceAutoActionRequest {
  /** @format int32 */
  id?: number;
  name?: string | null;
  pieceAutoActionUsers?: string[] | null;
  /**
   *
   *
   * 0 = NA
   *
   * 101 = Create
   *
   * 102 = Assign
   *
   * 103 = Store
   *
   * 104 = CropImage
   *
   * 201 = Submit
   *
   * 202 = RequestForward
   *
   * 203 = Move
   *
   * 301 = RequestRecycle
   *
   * 302 = RequestOpenAndScan
   *
   * 303 = RequestMail
   *
   * 304 = RequestPickUp
   *
   * 305 = RequestShred
   *
   * 306 = RequestDelivery
   *
   * 307 = RequestLockerPickUp
   *
   * 401 = FinishRecycle
   *
   * 402 = FinishOpenAndScan
   *
   * 403 = FinishMail
   *
   * 404 = FinishPickUp
   *
   * 405 = FinishShred
   *
   * 406 = FinishPickUpWithSignature
   *
   * 407 = FinishPickUpWithQrCode
   *
   * 408 = FinishDelivery
   *
   * 409 = FinishDeliveryWithSignature
   *
   * 410 = FinishDeliveryWithQrCode
   *
   * 411 = FinishPickUpWithPinCode
   *
   * 412 = FinishPickUpUnload
   *
   * 501 = RemindAction
   *
   * 502 = EscalateAction
   *
   * 503 = RemindReview
   *
   * 504 = EscalateReview
   *
   * 505 = RemindLockerPickup
   *
   * 506 = LockerExp
   *
   * 601 = ViewDoc
   *
   * 602 = DownloadDoc
   *
   * 603 = ScheduleDocumentNotViewedEscalation
   *
   * 604 = SchedulePieceNoActivityEscalation
   *
   * 605 = ScheduleDocumentNotViewed
   *
   * 606 = SchedulePieceNoActivity
   *
   * 607 = ScheduleLockersPickupReminder
   *
   * 608 = AutoCroppingComplete
   *
   * 609 = BarcodeDetectionComplete
   *
   * 901 = Archive
   *
   * -1 = Delete
   */
  action?: PieceAction;
  /**
   *
   *
   * 1 = Mail
   *
   * 2 = Package
   */
  pieceType?: PieceType;
  /**
   *
   *
   * 0 = AllRecipients
   *
   * 1 = SpecificRecipients
   */
  automationSetType?: AutomationSetType;
}

export interface PieceAutoActionResponse {
  userMessage?: string | null;
  objectData: PieceAutoAction;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceComment {
  /** @format int32 */
  id: number;
  /** @format int32 */
  parentCommentId?: number | null;
  /** @format int32 */
  pieceId: number;
  /** @minLength 1 */
  text: string;
  user: User;
  /** @format date-time */
  created: string;
  /** @format date-time */
  updated: string;
}

export interface PieceCommentArrayResponse {
  userMessage?: string | null;
  objectData: PieceComment[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceCommentListResponse {
  userMessage?: string | null;
  objectData: PieceComment[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceCommentResponse {
  userMessage?: string | null;
  objectData: PieceComment;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceCounters {
  pendingCounters: PieceCountersPending;
  inboxCounters: PieceCountersInbox;
  activeCounters: PieceActiveCounters;
}

export interface PieceCountersInbox {
  /** @format int32 */
  inboxNewCount: number;
  /** @format int32 */
  inboxInProcessCount: number;
  /** @format int32 */
  inboxCompletedCount: number;
}

export interface PieceCountersPending {
  /** @format int32 */
  openAndScanRequestedCount: number;
  /** @format int32 */
  pickUpRequestedCount: number;
  /** @format int32 */
  recycleRequestedCount: number;
  /** @format int32 */
  mailToMeRequestedCount: number;
  /** @format int32 */
  shredRequestedCount: number;
  /** @format int32 */
  deliverRequestedCount: number;
}

export interface PieceCountersResponse {
  userMessage?: string | null;
  objectData: PieceCounters;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceCropped {
  /** @format int32 */
  pieceId: number;
  croppedAsset: PieceAsset;
  isSuccess: boolean;
}

export interface PieceDoc {
  /** @format int32 */
  id: number;
  /**
   *
   *
   * 0 = Deleted
   *
   * 1 = Active
   */
  status: Status;
  piecePages: PieceDocPage[];
  /** @minLength 1 */
  pdfKey: string;
  /** @minLength 1 */
  pdfUrl: string;
}

export interface PieceDocPage {
  /** @format int32 */
  id: number;
  /** @format int32 */
  pieceDocId: number;
  /** @minLength 1 */
  originalKey: string;
  /** @minLength 1 */
  croppedKey: string;
  /** @minLength 1 */
  thumbnailKey: string;
  /** @minLength 1 */
  originalUrl: string;
  /** @minLength 1 */
  croppedUrl: string;
  /** @minLength 1 */
  thumbnailUrl: string;
  /** @format int32 */
  sortOrder: number;
}

export interface PieceDocPageResponse {
  userMessage?: string | null;
  objectData: PieceDocPage;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceDocPageStorage {
  /** @minLength 1 */
  croppedUrl: string;
  /** @minLength 1 */
  originalUrl: string;
  /** @minLength 1 */
  thumbnailUrl: string;
  /** @minLength 1 */
  croppedKey: string;
  /** @minLength 1 */
  originalKey: string;
  /** @minLength 1 */
  thumbnailKey: string;
}

export interface PieceDocPageStorageResponse {
  userMessage?: string | null;
  objectData: PieceDocPageStorage;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceDocResponse {
  userMessage?: string | null;
  objectData: PieceDoc;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceDocStorage {
  /** @minLength 1 */
  pdfUrl: string;
  /** @minLength 1 */
  pdfKey: string;
}

export interface PieceDocStorageResponse {
  userMessage?: string | null;
  objectData: PieceDocStorage;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceDuplicateFound {
  /** @format int32 */
  pieceId?: number;
}

export interface PieceEvent {
  userId?: string | null;
  /** @minLength 1 */
  actionTitle: string;
  /** @format date-time */
  created: string;
  /** @format int32 */
  lifecycleStage: number;
  /**
   *
   *
   * 0 = NA
   *
   * 101 = Create
   *
   * 102 = Assign
   *
   * 103 = Store
   *
   * 104 = CropImage
   *
   * 201 = Submit
   *
   * 202 = RequestForward
   *
   * 203 = Move
   *
   * 301 = RequestRecycle
   *
   * 302 = RequestOpenAndScan
   *
   * 303 = RequestMail
   *
   * 304 = RequestPickUp
   *
   * 305 = RequestShred
   *
   * 306 = RequestDelivery
   *
   * 307 = RequestLockerPickUp
   *
   * 401 = FinishRecycle
   *
   * 402 = FinishOpenAndScan
   *
   * 403 = FinishMail
   *
   * 404 = FinishPickUp
   *
   * 405 = FinishShred
   *
   * 406 = FinishPickUpWithSignature
   *
   * 407 = FinishPickUpWithQrCode
   *
   * 408 = FinishDelivery
   *
   * 409 = FinishDeliveryWithSignature
   *
   * 410 = FinishDeliveryWithQrCode
   *
   * 411 = FinishPickUpWithPinCode
   *
   * 412 = FinishPickUpUnload
   *
   * 501 = RemindAction
   *
   * 502 = EscalateAction
   *
   * 503 = RemindReview
   *
   * 504 = EscalateReview
   *
   * 505 = RemindLockerPickup
   *
   * 506 = LockerExp
   *
   * 601 = ViewDoc
   *
   * 602 = DownloadDoc
   *
   * 603 = ScheduleDocumentNotViewedEscalation
   *
   * 604 = SchedulePieceNoActivityEscalation
   *
   * 605 = ScheduleDocumentNotViewed
   *
   * 606 = SchedulePieceNoActivity
   *
   * 607 = ScheduleLockersPickupReminder
   *
   * 608 = AutoCroppingComplete
   *
   * 609 = BarcodeDetectionComplete
   *
   * 901 = Archive
   *
   * -1 = Delete
   */
  action: PieceAction;
  message: TermValue[];
  parent?: PieceEvent;
  /**
   *
   *
   * 0 = ChainOfCustody
   *
   * 1 = System
   */
  actionType: PieceActionType;
}

/**
 *
 *
 * 0 = Mailroom
 *
 * 1 = Inbox
 * @format int32
 */
export enum PieceFilterScope {
  Mailroom = 0,
  Inbox = 1,
}

export interface PieceLocation {
  container: Container;
  packageSpot?: string | null;
  /** @format int32 */
  orderNo: number;
  /** @minLength 1 */
  locationCodeFormatted: string;
}

export interface PieceLocationRequest {
  container?: ContainerRequest;
  packageSpot?: string | null;
  /** @format int64 */
  orderNo?: number | null;
}

export interface PiecePagedList {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  totalPages: number;
  items?: Piece[] | null;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface PiecePagedListResponse {
  userMessage?: string | null;
  objectData: PiecePagedList;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PieceResponse {
  userMessage?: string | null;
  objectData: Piece;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

/**
 *
 *
 * 0 = NA
 *
 * 10 = Created
 *
 * 20 = Assigned
 *
 * 30 = Submitted
 *
 * 40 = ActionRequested
 *
 * 50 = ActionTaken
 *
 * 60 = Archived
 *
 * -1 = Deleted
 * @format int32
 */
export enum PieceState {
  NA = 0,
  Created = 10,
  Assigned = 20,
  Submitted = 30,
  ActionRequested = 40,
  ActionTaken = 50,
  Archived = 60,
  Deleted = -1,
}

/**
 *
 *
 * 0 = Sent
 *
 * 1 = Unsent
 * @format int32
 */
export enum PieceStatus {
  Sent = 0,
  Unsent = 1,
}

export interface PieceStorage {
  pieceAssets: PieceAssetUpload[];
}

export interface PieceStorageResponse {
  userMessage?: string | null;
  objectData: PieceStorage;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

/**
 *
 *
 * 0 = All
 *
 * 1 = Stored
 *
 * 2 = Unstored
 * @format int32
 */
export enum PieceStoredStatusFilter {
  All = 0,
  Stored = 1,
  Unstored = 2,
}

export interface PieceThumbnailsCreated {
  /** @format int32 */
  pieceId: number;
  pieceThumbnailAssets: PieceAsset[];
}

/**
 *
 *
 * 1 = Mail
 *
 * 2 = Package
 * @format int32
 */
export enum PieceType {
  Mail = 1,
  Package = 2,
}

export interface PieceUser {
  /**
   *
   *
   * 0 = NA
   *
   * 10 = Created
   *
   * 20 = Assigned
   *
   * 30 = Submitted
   *
   * 40 = ActionRequested
   *
   * 50 = ActionTaken
   *
   * 60 = Archived
   *
   * -1 = Deleted
   */
  pieceState: PieceState;
  /** @minLength 1 */
  access: string;
  /** @minLength 1 */
  userId: string;
  nextActions: string[];
}

export interface PreparedImportUser {
  userName?: string | null;
  /** @minLength 1 */
  email: string;
  phoneNumber?: string | null;
  supervisorEmail?: string | null;
  assistantEmail?: string | null;
  roles: string[];
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  /**
   *
   *
   * 0 = Deleted
   *
   * 1 = Active
   */
  status: Status;
  location?: string | null;
  /**
   *
   *
   * 0 = Unchanged
   *
   * 1 = Added
   *
   * 2 = Activated
   *
   * 3 = Updated
   *
   * 4 = Inactivated
   *
   * 5 = Error
   */
  importState: ImportState;
  propertyModifications?: PropertyModification[] | null;
}

export interface PreparedImportUserListResponse {
  userMessage?: string | null;
  objectData: PreparedImportUser[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface Product {
  /** @format int32 */
  id: number;
  /**
   *
   *
   * 0 = Corporate
   *
   * 1 = University
   */
  businessDomain: BusinessDomain;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  description: string;
  /** @format double */
  monthlyPrice: number;
  /** @format double */
  yearlyPrice: number;
  features: ProductFeatures;
  /** @minLength 1 */
  payment_ProductId: string;
}

export interface ProductFeatures {
  /** @format int32 */
  storageMonths: number;
  /** @format int32 */
  mail: number;
  /** @format int32 */
  packages: number;
  /** @format int32 */
  recipients: number;
  /** @format int32 */
  mailrooms: number;
  support: boolean;
}

export interface ProductRequest {
  /** @format int32 */
  id?: number | null;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  description: string;
  /** @format double */
  monthlyPrice: number;
  /** @format double */
  yearlyPrice: number;
  /** @format int32 */
  storageMonths: number;
  /** @format int32 */
  mail: number;
  /** @format int32 */
  packages: number;
  /** @format int32 */
  recipients: number;
  /** @format int32 */
  mailrooms: number;
  support: boolean;
}

export interface ProductResponse {
  userMessage?: string | null;
  objectData: Product;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface ProfileImageStorage {
  /** @minLength 1 */
  uploadUrl: string;
  /** @minLength 1 */
  profileImageUrl: string;
}

export interface ProfileImageStorageResponse {
  userMessage?: string | null;
  objectData: ProfileImageStorage;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface PropertyModification {
  name?: string | null;
  originalValue?: string | null;
  currentValue?: string | null;
}

export interface ReceiveSession {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created: string;
  /** @format int32 */
  totalPiecesCount: number;
  /** @format int32 */
  readyPiecesCount: number;
  /** @format int32 */
  reviewPiecesCount: number;
  /** @format int32 */
  invalidPiecesCount: number;
  user: User;
  /**
   *
   *
   * 1 = DateReceived
   *
   * 2 = HighDensityMail
   *
   * 3 = MailBox
   *
   * 4 = Location
   *
   * 5 = Carrier
   *
   * 6 = Lockers
   */
  workflow: Workflow;
  receiveSessionContainer: ReceiveSessionContainer;
  lastPieceAssets?: PieceAsset[] | null;
  isClosed: boolean;
}

export interface ReceiveSessionContainer {
  /** @format int32 */
  containerId?: number | null;
  /** @format date-time */
  createdDate?: string | null;
  /** @format int32 */
  containerTypeId?: number | null;
  containerNumber?: string | null;
  /**
   *
   *
   * 0 = None
   *
   * 1 = AddToBack
   *
   * 2 = AddToFront
   */
  sortOrder?: ContainerSortOrder;
}

export interface ReceiveSessionContainerRequest {
  /** @format int32 */
  containerId?: number | null;
  /** @format date-time */
  createdDate?: string | null;
  /** @format int32 */
  containerTypeId?: number | null;
  containerNumber?: string | null;
  /**
   *
   *
   * 0 = None
   *
   * 1 = AddToBack
   *
   * 2 = AddToFront
   */
  sortOrder?: ContainerSortOrder;
}

/**
 *
 *
 * 0 = All
 *
 * 1 = InProgress
 *
 * 2 = Closed
 * @format int32
 */
export enum ReceiveSessionFilterStatus {
  All = 0,
  InProgress = 1,
  Closed = 2,
}

export interface ReceiveSessionPagedList {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  totalPages: number;
  items?: ReceiveSession[] | null;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface ReceiveSessionPagedListResponse {
  userMessage?: string | null;
  objectData: ReceiveSessionPagedList;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface ReceiveSessionRequest {
  /**
   *
   *
   * 1 = Single
   *
   * 2 = Automated
   *
   * 3 = Images
   */
  captureMethod: CaptureMethod;
  /**
   *
   *
   * 1 = Mail
   *
   * 2 = Package
   */
  pieceType: PieceType;
  /**
   *
   *
   * 1 = DateReceived
   *
   * 2 = HighDensityMail
   *
   * 3 = MailBox
   *
   * 4 = Location
   *
   * 5 = Carrier
   *
   * 6 = Lockers
   */
  workflow: Workflow;
  receiveSessionContainer?: ReceiveSessionContainerRequest;
}

export interface ReceiveSessionResponse {
  userMessage?: string | null;
  objectData: ReceiveSession;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface RecipientAutoDetection {
  /** @format int32 */
  pieceId: number;
  recipientId?: string | null;
  recipientName?: string | null;
  recipientAddress?: string | null;
  recipientSearchString?: string | null;
  /**
   *
   *
   * 0 = Identified
   *
   * 1 = IdentifiedBasedOnPreviousAssignment
   *
   * 2 = IdentifiedVerificationRequired
   *
   * 3 = IdentifiedMultipleMatches
   *
   * 4 = IdentifiedAndNotExist
   *
   * 5 = Failed
   */
  status: RecipientAutoDetectionStatus;
  recipient?: User;
}

/**
 *
 *
 * 0 = Identified
 *
 * 1 = IdentifiedBasedOnPreviousAssignment
 *
 * 2 = IdentifiedVerificationRequired
 *
 * 3 = IdentifiedMultipleMatches
 *
 * 4 = IdentifiedAndNotExist
 *
 * 5 = Failed
 * @format int32
 */
export enum RecipientAutoDetectionStatus {
  Identified = 0,
  IdentifiedBasedOnPreviousAssignment = 1,
  IdentifiedVerificationRequired = 2,
  IdentifiedMultipleMatches = 3,
  IdentifiedAndNotExist = 4,
  Failed = 5,
}

export interface Setting {
  /**
   *
   *
   * 1 = String
   *
   * 2 = Boolean
   *
   * 3 = Number
   *
   * 4 = Array
   *
   * 5 = Json
   */
  type: SettingType;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  value: string;
  /**
   *
   *
   * 1 = General
   *
   * 2 = Notifications
   *
   * 3 = Mailroom
   *
   * 4 = UserRegistration
   *
   * 5 = AvailableActions
   *
   * 6 = AvailableNotifications
   *
   * 7 = ReceiveDefaults
   *
   * 8 = PieceAutomatedActions
   *
   * -1 = Internal
   */
  group: SettingGroup;
  /** @minLength 1 */
  description: string;
  /** @minLength 1 */
  companySettingValue: string;
  /** @minLength 1 */
  userSettingValue: string;
  /** @minLength 1 */
  mailroomSettingValue: string;
}

export interface SettingArrayResponse {
  userMessage?: string | null;
  objectData: Setting[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

/**
 *
 *
 * 1 = General
 *
 * 2 = Notifications
 *
 * 3 = Mailroom
 *
 * 4 = UserRegistration
 *
 * 5 = AvailableActions
 *
 * 6 = AvailableNotifications
 *
 * 7 = ReceiveDefaults
 *
 * 8 = PieceAutomatedActions
 *
 * -1 = Internal
 * @format int32
 */
export enum SettingGroup {
  General = 1,
  Notifications = 2,
  Mailroom = 3,
  UserRegistration = 4,
  AvailableActions = 5,
  AvailableNotifications = 6,
  ReceiveDefaults = 7,
  PieceAutomatedActions = 8,
  Internal = -1,
}

export interface SettingRequest {
  /**
   *
   *
   * 1 = String
   *
   * 2 = Boolean
   *
   * 3 = Number
   *
   * 4 = Array
   *
   * 5 = Json
   */
  type?: SettingType;
  name?: string | null;
  value?: string | null;
  /**
   *
   *
   * 1 = General
   *
   * 2 = Notifications
   *
   * 3 = Mailroom
   *
   * 4 = UserRegistration
   *
   * 5 = AvailableActions
   *
   * 6 = AvailableNotifications
   *
   * 7 = ReceiveDefaults
   *
   * 8 = PieceAutomatedActions
   *
   * -1 = Internal
   */
  group?: SettingGroup;
  description?: string | null;
  companySettingValue?: string | null;
  userSettingValue?: string | null;
  mailroomSettingValue?: string | null;
}

export interface SettingResponse {
  userMessage?: string | null;
  objectData: Setting;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

/**
 *
 *
 * 1 = String
 *
 * 2 = Boolean
 *
 * 3 = Number
 *
 * 4 = Array
 *
 * 5 = Json
 * @format int32
 */
export enum SettingType {
  String = 1,
  Boolean = 2,
  Number = 3,
  Array = 4,
  Json = 5,
}

/**
 *
 *
 * 0 = Deleted
 *
 * 1 = Active
 * @format int32
 */
export enum Status {
  Deleted = 0,
  Active = 1,
}

export interface StringResponse {
  userMessage?: string | null;
  /** @minLength 1 */
  objectData: string;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface Subscription {
  /** @format int32 */
  id: number;
  /** @format date-time */
  created: string;
  /** @format int32 */
  trialPeriodDays: number;
  /**
   *
   *
   * 0 = Trial
   *
   * 1 = TrialExpired
   *
   * 2 = Live
   *
   * 3 = Dunning
   *
   * 4 = Unpaid
   *
   * 5 = Future
   *
   * 6 = NonRenewing
   *
   * 7 = Canceled
   *
   * 8 = Expired
   */
  subscriptionStatus: SubscriptionStatus;
  /** @format int32 */
  remainingTrialDays: number;
  /**
   *
   *
   * 0 = Months
   *
   * 1 = Years
   */
  payment_Interval: PaymentInterval;
  product: Product;
  /** @format date-time */
  blockedAt?: string | null;
  /** @format date-time */
  cancelsAt?: string | null;
  subscriptionAddons: SubscriptionAddon[];
}

export interface SubscriptionAddon {
  /** @format int32 */
  id: number;
  /** @format int32 */
  subscriptionId: number;
  /** @format int32 */
  quantity: number;
  product: Product;
}

export interface SubscriptionAddonRequest {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  productId?: number;
  /** @format int32 */
  quantity?: number;
  /** @format int32 */
  quantityAdded?: number;
}

export interface SubscriptionArrayResponse {
  userMessage?: string | null;
  objectData: Subscription[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface SubscriptionProduct {
  subscriptionProduct: Product;
  addonProducts: Product[];
}

export interface SubscriptionProductArrayResponse {
  userMessage?: string | null;
  objectData: SubscriptionProduct[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface SubscriptionProductResponse {
  userMessage?: string | null;
  objectData: SubscriptionProduct;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface SubscriptionRequest {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  productId?: number;
  defaultPaymentMethod?: string | null;
  /**
   *
   *
   * 0 = Months
   *
   * 1 = Years
   */
  payment_Interval?: PaymentInterval;
  subscriptionAddons?: SubscriptionAddonRequest[] | null;
}

export interface SubscriptionResponse {
  userMessage?: string | null;
  objectData: Subscription;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

/**
 *
 *
 * 0 = Trial
 *
 * 1 = TrialExpired
 *
 * 2 = Live
 *
 * 3 = Dunning
 *
 * 4 = Unpaid
 *
 * 5 = Future
 *
 * 6 = NonRenewing
 *
 * 7 = Canceled
 *
 * 8 = Expired
 * @format int32
 */
export enum SubscriptionStatus {
  Trial = 0,
  TrialExpired = 1,
  Live = 2,
  Dunning = 3,
  Unpaid = 4,
  Future = 5,
  NonRenewing = 6,
  Canceled = 7,
  Expired = 8,
}

export interface SubscriptionSummary {
  /** @format int32 */
  storageMonths: number;
  /** @format int32 */
  maxMail: number;
  /** @format int32 */
  maxPackages: number;
  /** @format int32 */
  maxRecipients: number;
  /** @format int32 */
  maxMailrooms: number;
  /** @format int32 */
  usedMail: number;
  /** @format int32 */
  usedPackages: number;
  /** @format int32 */
  usedRecipients: number;
  /** @format int32 */
  usedMailrooms: number;
  support: boolean;
  subscription: Subscription;
  upcomingInvoice: UpcomingInvoice;
  lastPaymentError?: PaymentError;
  /** @format double */
  totalSum?: number;
}

export interface SubscriptionSummaryResponse {
  userMessage?: string | null;
  objectData: SubscriptionSummary;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface Sync {
  products: Product[];
  subscriptions: Subscription[];
}

export interface SyncResponse {
  userMessage?: string | null;
  objectData: Sync;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface TermValue {
  /** @minLength 1 */
  text: string;
  url?: string | null;
}

/**
 *
 *
 * 0 = None
 *
 * 1 = Sent
 *
 * 2 = Confirmed
 *
 * 3 = Failed
 *
 * 4 = Canceled
 * @format int32
 */
export enum TrackingNumberAnnouncementStatus {
  None = 0,
  Sent = 1,
  Confirmed = 2,
  Failed = 3,
  Canceled = 4,
}

/**
 *
 *
 * 0 = Valid
 *
 * 1 = BadFormat
 *
 * 2 = Conflict
 * @format int32
 */
export enum TrackingNumberValidationStatus {
  Valid = 0,
  BadFormat = 1,
  Conflict = 2,
}

export interface UpcomingInvoice {
  /** @format double */
  addonsAmount: number;
  /** @format double */
  productAmount: number;
  /** @format date-time */
  dueDate: string;
  /** @format double */
  startingBalance: number;
  /** @format double */
  endingBalance: number;
  /** @format double */
  appliedBalance: number;
  /** @format double */
  amountTotal: number;
  /** @format double */
  subtotal: number;
  /** @format double */
  proratedCredit: number;
  lines: UpcomingInvoiceLineItem[];
}

export interface UpcomingInvoiceLineItem {
  /** @minLength 1 */
  description: string;
  /** @format int64 */
  quantity: number;
  /** @format date-time */
  periodStart: string;
  /** @format date-time */
  periodEnd: string;
  proration?: boolean;
  /** @format double */
  unitPrice?: number | null;
  /** @format double */
  amount?: number | null;
}

export interface UpcomingInvoiceResponse {
  userMessage?: string | null;
  objectData: UpcomingInvoice;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface UpdateActiveSpanRequest {
  /** @format int32 */
  id?: number;
  /**
   *
   *
   * 0 = Sunday
   *
   * 1 = Monday
   *
   * 2 = Tuesday
   *
   * 3 = Wednesday
   *
   * 4 = Thursday
   *
   * 5 = Friday
   *
   * 6 = Saturday
   */
  dayOfWeek: DayOfWeek;
  /** @format int32 */
  open: number;
  /** @format int32 */
  close: number;
}

export interface UpdateCompanyRequest {
  /** @format int32 */
  id?: number;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  subdomain?: string | null;
  addresses?: AddressRequest[] | null;
}

export interface UpdateDeliveryRouteRequest {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  name: string;
  locations?: LocationRequest[] | null;
}

export interface UpdateHoursOfOperationRequest {
  /** @format int32 */
  id?: number;
  timeZoneInfoId?: string | null;
  activeSpans?: UpdateActiveSpanRequest[] | null;
}

export interface UpdateLocationRequest {
  /** @format int32 */
  id: number;
  name?: string | null;
  /** @format int32 */
  sortOrder?: number;
  userOperations?: UserLocationOperation[] | null;
}

export interface UpdateMailroomRequest {
  /** @format int32 */
  id: number;
  name?: string | null;
  address?: AddressRequest;
  email?: string | null;
  /** @format date-time */
  openTime?: string | null;
  /** @format date-time */
  closeTime?: string | null;
  hoursOfOperation?: UpdateHoursOfOperationRequest;
}

export interface UpdatePieceCommentRequest {
  /** @format int32 */
  id?: number;
  text?: string | null;
}

export interface UpdatePieceDocRequest {
  /** @format int32 */
  id?: number;
  /** @maxLength 255 */
  pdfKey?: string | null;
}

export interface UpdatePiecePageRequest {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  pieceDocId: number;
  /**
   * @minLength 1
   * @maxLength 255
   */
  originalKey: string;
  /** @maxLength 255 */
  croppedKey?: string | null;
  /** @maxLength 255 */
  thumbnailKey?: string | null;
  /** @format int32 */
  sortOrder?: number;
}

export interface UpdatePieceRequest {
  /** @format int32 */
  id: number;
  recipientId?: string | null;
  pieceLocation?: PieceLocationRequest;
  /**
   *
   *
   * 1 = Mail
   *
   * 2 = Package
   */
  pieceType?: PieceType;
  croppedFileBase64?: string | null;
}

export interface UpdatePiecesRequest {
  /**
   *
   *
   * 0 = NA
   *
   * 101 = Create
   *
   * 102 = Assign
   *
   * 103 = Store
   *
   * 104 = CropImage
   *
   * 201 = Submit
   *
   * 202 = RequestForward
   *
   * 203 = Move
   *
   * 301 = RequestRecycle
   *
   * 302 = RequestOpenAndScan
   *
   * 303 = RequestMail
   *
   * 304 = RequestPickUp
   *
   * 305 = RequestShred
   *
   * 306 = RequestDelivery
   *
   * 307 = RequestLockerPickUp
   *
   * 401 = FinishRecycle
   *
   * 402 = FinishOpenAndScan
   *
   * 403 = FinishMail
   *
   * 404 = FinishPickUp
   *
   * 405 = FinishShred
   *
   * 406 = FinishPickUpWithSignature
   *
   * 407 = FinishPickUpWithQrCode
   *
   * 408 = FinishDelivery
   *
   * 409 = FinishDeliveryWithSignature
   *
   * 410 = FinishDeliveryWithQrCode
   *
   * 411 = FinishPickUpWithPinCode
   *
   * 412 = FinishPickUpUnload
   *
   * 501 = RemindAction
   *
   * 502 = EscalateAction
   *
   * 503 = RemindReview
   *
   * 504 = EscalateReview
   *
   * 505 = RemindLockerPickup
   *
   * 506 = LockerExp
   *
   * 601 = ViewDoc
   *
   * 602 = DownloadDoc
   *
   * 603 = ScheduleDocumentNotViewedEscalation
   *
   * 604 = SchedulePieceNoActivityEscalation
   *
   * 605 = ScheduleDocumentNotViewed
   *
   * 606 = SchedulePieceNoActivity
   *
   * 607 = ScheduleLockersPickupReminder
   *
   * 608 = AutoCroppingComplete
   *
   * 609 = BarcodeDetectionComplete
   *
   * 901 = Archive
   *
   * -1 = Delete
   */
  action?: PieceAction;
  /** @maxLength 255 */
  pickupSignatureKey?: string | null;
  /** @format date-time */
  pickupDate?: string | null;
  /** @format int32 */
  addressId?: number | null;
  qrCode?: string | null;
  /** @maxLength 100 */
  trackingNumber?: string | null;
  pieces?: UpdatePieceRequest[] | null;
}

export interface UpdateUserRequest {
  id?: string | null;
  userName?: string | null;
  supervisorId?: string | null;
  addresses?: AddressRequest[] | null;
  roles?: string[] | null;
  profileImageUrl?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  active?: boolean;
  assistants?: AssistantRequest[] | null;
  /** @format int32 */
  locationId?: number | null;
  phoneNumber?: string | null;
}

export interface User {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  userName: string;
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  phoneNumber: string;
  /** @minLength 1 */
  supervisorId: string;
  roles: string[];
  company: Company;
  addresses: Address[];
  /** @format date-time */
  lockoutEnd: string;
  twoFactorEnabled: boolean;
  phoneNumberConfirmed: boolean;
  emailConfirmed: boolean;
  lockoutEnabled: boolean;
  /** @format int32 */
  accessFailedCount: number;
  /** @minLength 1 */
  profileImageUrl: string;
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  /** @minLength 1 */
  displayName: string;
  /** @format date-time */
  created: string;
  active: boolean;
  location: Location;
  assistants: UserMin[];
}

export interface UserArrayResponse {
  userMessage?: string | null;
  objectData: User[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface UserDevice {
  /** @format int32 */
  id?: number;
  connectionId?: string | null;
  /** @format date-time */
  created?: string;
}

export interface UserLocation {
  /** @minLength 1 */
  userId: string;
  user: UserMin;
  /** @minLength 1 */
  locationId: string;
  /** @minLength 1 */
  addedById: string;
  /** @format date-time */
  addedOn: string;
  /** @minLength 1 */
  addedByDisplayName: string;
}

export interface UserLocationOperation {
  /** @minLength 1 */
  userId: string;
  /**
   *
   *
   * 0 = Add
   *
   * 1 = Remove
   */
  operation: LocationUserOperationType;
}

export interface UserMin {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  userName: string;
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  /** @minLength 1 */
  profileImageUrl: string;
  /** @minLength 1 */
  displayName: string;
  /** @format int32 */
  locationId: number;
}

export interface UserMinPagedList {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  totalPages: number;
  items?: UserMin[] | null;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface UserMinPagedListResponse {
  userMessage?: string | null;
  objectData: UserMinPagedList;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface UserPagedList {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  totalPages: number;
  items?: User[] | null;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface UserPagedListResponse {
  userMessage?: string | null;
  objectData: UserPagedList;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface UserResponse {
  userMessage?: string | null;
  objectData: User;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface ValidationProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  errors?: Record<string, string[]>;
  [key: string]: any;
}

export interface Variable {
  /** @minLength 1 */
  key: string;
  /** @minLength 1 */
  value: string;
}

export interface VariableArrayResponse {
  userMessage?: string | null;
  objectData: Variable[];
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

export interface VariableRequest {
  /** @minLength 1 */
  key: string;
  value?: string | null;
}

export interface VariableResponse {
  userMessage?: string | null;
  objectData: Variable;
  errors?: Error[] | null;
  validationResult?: ValidationProblemDetails;
}

/**
 *
 *
 * 1 = DateReceived
 *
 * 2 = HighDensityMail
 *
 * 3 = MailBox
 *
 * 4 = Location
 *
 * 5 = Carrier
 *
 * 6 = Lockers
 * @format int32
 */
export enum Workflow {
  DateReceived = 1,
  HighDensityMail = 2,
  MailBox = 3,
  Location = 4,
  Carrier = 5,
  Lockers = 6,
}

export interface CompanyFilteredListParams {
  SearchText?: string;
  SortBy?: string;
  Descending?: boolean;
  /** @format int32 */
  ItemsPerPage?: number;
  /** @format int32 */
  PageNumber?: number;
}

export interface ContainerFilteredListParams {
  Workflows?: Workflow[];
  /**
   *
   *
   * 0 = All
   *
   * 1 = WithUnsentItems
   *
   * 2 = WithoutUnsentItems
   */
  StorageGroups?: ContainerFilterStorageGroups;
  SearchText?: string;
  SortBy?: string;
  Descending?: boolean;
  /** @format int32 */
  ItemsPerPage?: number;
  /** @format int32 */
  PageNumber?: number;
}

export interface ContainerContainerByRequestListParams {
  /** @format date-time */
  CreatedDate?: string;
  /** @format int32 */
  ContainerTypeId?: number;
  ContainerNumber?: string;
  SortOrder?: string;
  UserId?: string;
}

export interface DashboardGetInboundTrendsListParams {
  /**
   *
   *
   * 0 = Week
   *
   * 1 = Month
   *
   * 2 = Year
   *
   * 3 = Today
   *
   * 4 = NotToday
   */
  periodType?: PeriodType;
}

export interface DashboardGetPercentageOfRecycleResponseListParams {
  /**
   *
   *
   * 0 = Week
   *
   * 1 = Month
   *
   * 2 = Year
   *
   * 3 = Today
   *
   * 4 = NotToday
   */
  periodType?: PeriodType;
}

export interface DashboardGetAverageResponseTimeListParams {
  /**
   *
   *
   * 0 = Week
   *
   * 1 = Month
   *
   * 2 = Year
   *
   * 3 = Today
   *
   * 4 = NotToday
   */
  periodType?: PeriodType;
}

export interface DashboardGetDailyRequestsListParams {
  /**
   *
   *
   * 0 = Week
   *
   * 1 = Month
   *
   * 2 = Year
   *
   * 3 = Today
   *
   * 4 = NotToday
   */
  periodType?: PeriodType;
}

export interface DeliveryRoutesListParams {
  Name?: string;
  /** @format int32 */
  LocationId?: number;
  SearchText?: string;
  SortBy?: string;
  Descending?: boolean;
  /** @format int32 */
  ItemsPerPage?: number;
  /** @format int32 */
  PageNumber?: number;
}

export interface DeliveryRoutesDeleteParams {
  /** @format int32 */
  id?: number;
}

export interface LocationListParams {
  Name?: string;
  SearchText?: string;
  SortBy?: string;
  Descending?: boolean;
  /** @format int32 */
  ItemsPerPage?: number;
  /** @format int32 */
  PageNumber?: number;
}

export interface LocationDeleteParams {
  /** @format int32 */
  id?: number;
}

export interface PieceUnsentListParams {
  /** @format int32 */
  containerId?: number;
}

export interface PieceFilteredListParams {
  /**
   *
   *
   * 0 = Mailroom
   *
   * 1 = Inbox
   */
  Scope?: PieceFilterScope;
  /** @format int32 */
  ContainerId?: number;
  /**
   *
   *
   * 0 = NA
   *
   * 10 = Created
   *
   * 20 = Assigned
   *
   * 30 = Submitted
   *
   * 40 = ActionRequested
   *
   * 50 = ActionTaken
   *
   * 60 = Archived
   *
   * -1 = Deleted
   */
  PieceState?: PieceState;
  /**
   *
   *
   * 0 = Sent
   *
   * 1 = Unsent
   */
  PieceStatus?: PieceStatus;
  /**
   *
   *
   * 0 = NA
   *
   * 101 = Create
   *
   * 102 = Assign
   *
   * 103 = Store
   *
   * 104 = CropImage
   *
   * 201 = Submit
   *
   * 202 = RequestForward
   *
   * 203 = Move
   *
   * 301 = RequestRecycle
   *
   * 302 = RequestOpenAndScan
   *
   * 303 = RequestMail
   *
   * 304 = RequestPickUp
   *
   * 305 = RequestShred
   *
   * 306 = RequestDelivery
   *
   * 307 = RequestLockerPickUp
   *
   * 401 = FinishRecycle
   *
   * 402 = FinishOpenAndScan
   *
   * 403 = FinishMail
   *
   * 404 = FinishPickUp
   *
   * 405 = FinishShred
   *
   * 406 = FinishPickUpWithSignature
   *
   * 407 = FinishPickUpWithQrCode
   *
   * 408 = FinishDelivery
   *
   * 409 = FinishDeliveryWithSignature
   *
   * 410 = FinishDeliveryWithQrCode
   *
   * 411 = FinishPickUpWithPinCode
   *
   * 412 = FinishPickUpUnload
   *
   * 501 = RemindAction
   *
   * 502 = EscalateAction
   *
   * 503 = RemindReview
   *
   * 504 = EscalateReview
   *
   * 505 = RemindLockerPickup
   *
   * 506 = LockerExp
   *
   * 601 = ViewDoc
   *
   * 602 = DownloadDoc
   *
   * 603 = ScheduleDocumentNotViewedEscalation
   *
   * 604 = SchedulePieceNoActivityEscalation
   *
   * 605 = ScheduleDocumentNotViewed
   *
   * 606 = SchedulePieceNoActivity
   *
   * 607 = ScheduleLockersPickupReminder
   *
   * 608 = AutoCroppingComplete
   *
   * 609 = BarcodeDetectionComplete
   *
   * 901 = Archive
   *
   * -1 = Delete
   */
  LifecycleStage?: PieceAction;
  /**
   *
   *
   * 0 = Week
   *
   * 1 = Month
   *
   * 2 = Year
   *
   * 3 = Today
   *
   * 4 = NotToday
   */
  Period?: PeriodType;
  /**
   *
   *
   * 1 = Mail
   *
   * 2 = Package
   */
  Type?: PieceType;
  /** @format date-time */
  StartDate?: string;
  /** @format date-time */
  EndDate?: string;
  ReceivedBy?: string;
  /** @format uuid */
  ReceiveSessionId?: string;
  /** @format int32 */
  RecipientLocationId?: number;
  IsInMailCenterPiece?: boolean;
  /**
   *
   *
   * 0 = All
   *
   * 1 = Assigned
   *
   * 2 = Unassigned
   */
  AssignmentStatus?: PieceAssignmentStatusFilter;
  /**
   *
   *
   * 0 = All
   *
   * 1 = Stored
   *
   * 2 = Unstored
   */
  StoredStatus?: PieceStoredStatusFilter;
  SearchText?: string;
  SortBy?: string;
  Descending?: boolean;
  /** @format int32 */
  ItemsPerPage?: number;
  /** @format int32 */
  PageNumber?: number;
}

export interface PieceAnnotationsFilteredListParams {
  /** @format int32 */
  PieceId?: number;
  /** @format uuid */
  ReceiveSessionId?: string;
  /**
   *
   *
   * 0 = Recipient
   *
   * 1 = Sender
   *
   * 2 = TrackingNumber
   *
   * 3 = Carrier
   *
   * 4 = PackageWeight
   *
   * 5 = PackageSize
   *
   * 6 = ReferenceNumber
   *
   * 7 = PurchaseOrderNumber
   *
   * 8 = InvoiceNumber
   */
  AnnotationType?: AnnotationType;
  /**
   *
   *
   * 0 = Valid
   *
   * 1 = BadFormat
   *
   * 2 = Conflict
   */
  TrackingNumberValidationStatus?: TrackingNumberValidationStatus;
  SearchText?: string;
  SortBy?: string;
  Descending?: boolean;
  /** @format int32 */
  ItemsPerPage?: number;
  /** @format int32 */
  PageNumber?: number;
}

export interface ReceiveSessionFilteredListParams {
  /** @format date-time */
  StartDate?: string;
  /** @format date-time */
  EndDate?: string;
  /**
   *
   *
   * 0 = All
   *
   * 1 = InProgress
   *
   * 2 = Closed
   */
  Status?: ReceiveSessionFilterStatus;
  Workflows?: Workflow[];
  CreatedBy?: string;
  SearchText?: string;
  SortBy?: string;
  Descending?: boolean;
  /** @format int32 */
  ItemsPerPage?: number;
  /** @format int32 */
  PageNumber?: number;
}

export interface SettingListParams {
  /** Setting group. */
  group?: string;
}

export interface UserFilteredListParams {
  UserRole?: string;
  /**
   *
   *
   * 0 = Deleted
   *
   * 1 = Active
   */
  UserStatus?: Status;
  /** @format int32 */
  LocationId?: number;
  SearchText?: string;
  SortBy?: string;
  Descending?: boolean;
  /** @format int32 */
  ItemsPerPage?: number;
  /** @format int32 */
  PageNumber?: number;
}

export interface UserMinFilteredListParams {
  UserRole?: string;
  /**
   *
   *
   * 0 = Deleted
   *
   * 1 = Active
   */
  UserStatus?: Status;
  /** @format int32 */
  LocationId?: number;
  SearchText?: string;
  SortBy?: string;
  Descending?: boolean;
  /** @format int32 */
  ItemsPerPage?: number;
  /** @format int32 */
  PageNumber?: number;
}

export interface UserSetCompanyUpdateParams {
  /** @format int32 */
  companyId?: number;
}

export interface NotificationHubOnTestMessageCreateParams {
  user?: string;
  message?: string;
}

export interface OnCameraStartCreateParams {
  /** @format uuid */
  receiveSessionId?: string;
}

export interface OnCameraRestartCreateParams {
  /** @format uuid */
  receiveSessionId?: string;
}

export interface OnSubmitReceiveSessionCreateParams {
  /** @format uuid */
  receiveSessionId?: string;
}

export interface OnCameraConnectedCreateParams {
  userDeviceResponse?: UserDevice;
}

export interface OnPieceRecipientDetectedCreateParams {
  recipientAutoDetectionResponse?: RecipientAutoDetection;
}

export interface OnPieceTrackingNumberDetectedCreateParams {
  pieceAnnotationsResponse?: PieceAnnotations;
}

export interface OnPieceCroppedCreateParams {
  pieceCroppedResponse?: PieceCropped;
}

export interface OnPieceThumbnailsCreatedCreateParams {
  pieceThumbnailsCreatedResponse?: PieceThumbnailsCreated;
}

export interface OnTrackingNumberStatusChangedCreateParams {
  pieceAnnotationResponse?: PieceAnnotation;
}

export interface OnPieceDuplicateFoundCreateParams {
  pieceDuplicateFoundResponse?: PieceDuplicateFound;
}

export interface OnUserProfileUpdatedCreateParams {
  userResponse?: User;
}
