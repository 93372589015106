import AddCommentRounded from '@mui/icons-material/AddCommentRounded';
import CloudUploadRounded from '@mui/icons-material/CloudUploadRounded';
import CropRotateRounded from '@mui/icons-material/CropRotateRounded';
import LocationOnRounded from '@mui/icons-material/LocationOnRounded';
import PersonPinRounded from '@mui/icons-material/PersonPinRounded';
import Rotate90DegreesCcwRounded from '@mui/icons-material/Rotate90DegreesCcwRounded';
import {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import { useUpdatePiecesMutation } from '@api/piece';
import {
  Piece,
  PieceAction,
  PieceAnnotation,
  PieceAssetSize,
  PieceAssetType,
  PieceStatus,
  PieceType,
  Workflow,
} from '@apiContract';
import { mailroomRoutes } from '@routes';
// eslint-disable-next-line import/named
import { isNumber, keys } from 'lodash/fp';
import { receivedDigitalApi } from 'src/redux/api';
import { dispatch as storeDispatch } from 'src/redux/store';

import AssigmentList from '@common/components/DrawerContent/AssigmentList';
import Barcode from '@common/components/DrawerContent/Barcode';
import LocationID from '@common/components/DrawerContent/LocationID';
import { ICropperRef } from '@common/components/ImageCropper';
import { ImageEditor } from '@common/components/ImageEditor';
import Loading from '@common/components/PercentLoading';
import { usePieceAnnotations } from '@common/hooks/usePieceAnnotation';
import { useSettings } from '@common/hooks/useSettings';
import { Options } from '@common/types/capture';
import { getBarcodeIconColor } from '@common/utils/annotations';
import { rotateImage } from '@common/utils/image';
import { getCompanyFromHost } from '@common/utils/url';

import { BarcodeIcon } from '@src/assets/icons/BarcodeIcon';
import { getPieceAssetUrl } from '@utils/piece';

import { CameraCapture } from '../../CameraCapture';
import Comments from '../components/Comments';
import NotFound from '../components/NotFound';
import ReceiveConfig from '../components/ReceiveConfig';
import ReviewItems from '../components/ReviewItems';
import UploadImages from '../components/UploadImages';
import {
  getButtonConfig,
  getIconConfig,
  getUserColorPieceStatus,
  getUserIconTooltip,
  isBarcodeLoading,
  nextStepReceiveConfig,
  parseUploadFiles,
  toBase64,
  tooltips,
} from '../helper';
import { ButtonConfig, DrawerTypes, IconConfig, IdV4, OnPieceCreateType, Steps, UploadRef } from '../types';
import { ActionTypes, FileOptionPayload, initStore, reducer } from './reducer';
import { useReceiveSession } from './useReceiveSession';
import { useReceiveSessionItems } from './useReceiveSessionItems';
import { useUpdateQueue } from './useUpdateQueue';
import { useUploadWorker } from './useUploadWorker';
import { useCaptureWebSockets } from './useWebsockets';

type ComponentByState = {
  Component:
    | ((props: any) => JSX.Element | null)
    | ForwardRefExoticComponent<PropsWithoutRef<unknown> & RefAttributes<unknown>>
    | null;
  props: object;
};

type DrawerComponent = {
  Component: ((props: any) => JSX.Element) | null;
  props: Record<string, unknown> & { onClose: () => void };
};

export const useCapture = (optionData: Options) => {
  const [params, setParams] = useSearchParams();
  const paramSessionId = params.get('sessionId');
  const { currentSessionProps, receiveSessionError, getSessionIdOrCreate } = useReceiveSession(
    !paramSessionId || paramSessionId === 'new' ? undefined : paramSessionId,
  );
  const [
    { files, cursor, captureState, drawer, receiveSession, cropReturnPath, uploadError, options, isLoading },
    dispatch,
  ] = useReducer(reducer, initStore(optionData, !paramSessionId || paramSessionId === 'new'));
  const navigate = useNavigate();

  const currentFile = cursor ? files[cursor] : null;

  const [, forceUpdate] = useState({});
  const setCaptureState = useCallback((nextCaptureState: Steps) => {
    dispatch({ type: ActionTypes.SET_CAPTURE_STATE, payload: nextCaptureState });
  }, []);

  usePieceAnnotations(
    (annotations: PieceAnnotation[]) => dispatch({ type: ActionTypes.SET_PIECE_BARCODES, payload: annotations }),
    isNumber(cursor) ? cursor : null,
  );

  if (
    captureState === Steps.Loading &&
    (receiveSessionError ||
      (currentSessionProps &&
        currentSessionProps.receiveSession &&
        !currentSessionProps.receiveSession.totalPiecesCount))
  ) {
    setCaptureState(Steps.NotFound);
  }

  if (
    captureState === Steps.Loading &&
    currentSessionProps?.receiveSession?.totalPiecesCount &&
    Math.round((100 / currentSessionProps.receiveSession.totalPiecesCount) * Object.keys(files).length) >= 100
  ) {
    setTimeout(() => {
      const captureType = params.get('captureType') as Steps;
      if (paramSessionId) {
        dispatch({ type: ActionTypes.SET_RECEIVE_SESSION, payload: paramSessionId });
      }
      setCaptureState(
        captureType && [Steps.Photo, Steps.Images].includes(captureType) ? captureType : Steps.ReviewItems,
      );
    }, 300);
  }

  useReceiveSessionItems(
    currentSessionProps,
    (items: Piece[]) => {
      dispatch({ type: ActionTypes.ADD_PIECE_LOAD_LIST, payload: items });
    },
    dispatch,
  );

  const setCursor = useCallback(
    (nextCursor: number) => dispatch({ type: ActionTypes.SET_CURSOR, payload: nextCursor }),
    [],
  );

  const setDrawer = useCallback((nextDrawer: DrawerTypes | null) => {
    dispatch({ type: ActionTypes.SET_DRAWER, payload: nextDrawer });
  }, []);

  const setFileOption = useCallback((payload: FileOptionPayload) => {
    dispatch({ type: ActionTypes.SET_FILE_OPTION, payload });
  }, []);

  const cropperRef = useRef<ICropperRef | null | undefined>(null);
  const uploadImagesRef = useRef<UploadRef>(null);
  const [updatePieces] = useUpdatePiecesMutation();

  const { addEvent } = useUpdateQueue();

  const settings: Record<string, boolean> = useSettings(['AutoCroppingEnable']);

  useEffect(
    () => () => {
      storeDispatch(receivedDigitalApi.util.invalidateTags(['RS']));
    },
    [],
  );

  useCaptureWebSockets(dispatch, setFileOption, options, addEvent);

  const onPieceCreated = ({ fileId, piece, status }: OnPieceCreateType) => {
    setFileOption({ id: fileId, option: 'status', value: status });
    if (piece) {
      setFileOption({ id: fileId, option: 'piece', value: piece });
      dispatch({ type: ActionTypes.UPDATE_FILE_ID, payload: fileId });
      dispatch({ type: ActionTypes.CHECK_NOT_SET_RECIPIENT, payload: { id: piece.id, addEvent } });
    }
  };

  const { addFile } = useUploadWorker(onPieceCreated);

  const handlePhotoClick = (photo: string) => {
    dispatch({ type: ActionTypes.INIT_FILE, payload: { photo } });
    setCaptureState(Steps.Review);
  };

  const handleRetake = () => {
    if (cursor) {
      dispatch({ type: ActionTypes.REMOVE_FILE, payload: cursor });
    }
    dispatch({ type: ActionTypes.SET_CURSOR, payload: null });
    setCaptureState(Steps.Photo);
  };

  const handleErrorStartReceive = () => {
    params.delete('sessionId');
    params.delete('captureType');
    params.append('sessionId', 'new');
    setParams(params);
    setCaptureState(Steps.ReceiveConfig);
  };

  const closeDrawer = () => setDrawer(null);

  const handleReviewItems = () => setCaptureState(Steps.ReviewItems);

  const handleConfirmReview = async () => {
    setCaptureState(Steps.Edit);
    const sessionId = await getSessionIdOrCreate(options);

    if (sessionId && cursor && currentFile && currentFile.photo) {
      addFile({ file: currentFile.photo, sessionId, fileId: cursor as string, host: getCompanyFromHost() });
      dispatch({ type: ActionTypes.SET_RECEIVE_SESSION, payload: sessionId });
      setFileOption({ id: cursor, option: 'status', value: 'queued' });
    }
  };

  const onUpload = useCallback(
    async (uploadedFiles: Array<File | string>) => {
      const sessionId = await getSessionIdOrCreate(options);

      if (sessionId) {
        const data = parseUploadFiles(uploadedFiles, sessionId);
        data.forEach(({ file, fileId }) => {
          if (file) {
            dispatch({ type: ActionTypes.INIT_FILE, payload: { photo: file, id: fileId } });
          }
        });
        addFile(data);

        dispatch({ type: ActionTypes.SET_RECEIVE_SESSION, payload: sessionId });
        setCaptureState(Steps.ReviewItems);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addFile, receiveSession, options],
  );

  const handleCrop = async (returnPath: Steps = Steps.Edit) => {
    if (!cursor) return;
    let edit = files[cursor].photo;
    if (files[cursor].photo && typeof files[cursor].photo !== 'string') {
      edit = (await toBase64(files[cursor].photo as File)) as string;
    }
    setFileOption({ id: cursor, option: 'prevImage', value: files[cursor].editImage });
    setFileOption({ id: cursor, option: 'editImage', value: edit });
    dispatch({ type: ActionTypes.SET_CROP_RETURN_PATH, payload: returnPath });
    setCaptureState(Steps.Crop);
  };

  const handleRotate = () => {
    if (!cursor) return;
    rotateImage(currentFile?.editImage || '', -90, (result) => {
      setFileOption({ id: cursor, option: 'editImage', value: result });
    });
  };

  const retryFailedPieces = async (id?: IdV4) => {
    const sessionId = await getSessionIdOrCreate(options);
    const failedPieces = Object.values(files).filter((item) => item.status === 'failed');

    if (failedPieces.length && sessionId) {
      const failedFiles = id
        ? { file: files[id].photo, sessionId, fileId: id, host: getCompanyFromHost() }
        : failedPieces.map((item) => ({ file: item.photo, sessionId, fileId: item.id as IdV4, host: getCompanyFromHost()  }));

      addFile(failedFiles);
      dispatch({ type: ActionTypes.SET_RECEIVE_SESSION, payload: sessionId });
      if (id) {
        setFileOption({ id, option: 'status', value: 'queued' });
      } else {
        failedPieces.forEach(({ id: piceId }) => setFileOption({ id: piceId, option: 'status', value: 'queued' }));
      }
    }
  };

  const updateReceiveConfig = (payload: Partial<Options>) => {
    dispatch({ type: ActionTypes.UPDATE_RECEIVE_CONFIG, payload });
  };

  const setIsLoading = (payload: boolean) => {
    dispatch({ type: ActionTypes.SET_IS_LOADING, payload });
  };

  const isUnresolvedPieces = () => {
    return Object.values(files).some((item) => {
      const { piece } = item;
      return !piece || !piece.recipient;
    });
  };

  const isUploadingPieces = () => {
    return Object.values(files).some(
      (item) => !['failed', 'detected', 'completed', 'loadedFromServer'].includes(item.status),
    );
  };

  const onNextState = () => {
    switch (captureState) {
      case Steps.ReceiveConfig: {
        if (options.captureMethod) {
          setCaptureState(nextStepReceiveConfig[options.captureMethod]);
        }
        break;
      }
      case Steps.Photo:
        setCaptureState(Steps.Review);
        break;
      case Steps.Review:
        setCaptureState(Steps.Edit);
        break;
      case Steps.Edit:
      case Steps.ReviewItems:
        setCaptureState(Steps.Photo);
        break;
      default:
        break;
    }
  };

  const getButtonsByState = (onClose: () => boolean | void): ButtonConfig[] => {
    switch (true) {
      case captureState === Steps.ReceiveConfig:
        return [getButtonConfig('Start Receiving', 'primary', onNextState, isLoading)];
      case captureState === Steps.Photo && keys(files).length > 0:
        return [getButtonConfig('Review Items', 'primary', handleReviewItems)];
      case captureState === Steps.Review:
        return [
          getButtonConfig('Retake', 'inherit', handleRetake),
          getButtonConfig('Confirm', 'primary', handleConfirmReview),
        ];
      case captureState === Steps.Edit:
        return [
          getButtonConfig('Review Items', 'inherit', handleReviewItems),
          getButtonConfig('Continue', 'primary', onNextState),
        ];
      case captureState === Steps.Crop:
        return [
          getButtonConfig('Cancel', 'inherit', () => {
            if (cursor) {
              setFileOption({ id: cursor, option: 'prevImage', value: null });
              setFileOption({ id: cursor, option: 'editImage', value: files[cursor].prevImage });
            }
            if (cropReturnPath) {
              setCaptureState(cropReturnPath);
            }
          }),
          getButtonConfig('Save Changes', 'primary', async () => {
            const img = await cropperRef.current?.done();
            if (cropReturnPath) {
              setCaptureState(cropReturnPath);
            }
            if (cursor) {
              const result = await updatePieces({
                action: PieceAction.CropImage,
                pieces: [
                  {
                    id: +cursor,
                    croppedFileBase64: img.split(',')[1],
                  },
                ],
              }).unwrap();
              setFileOption({ id: cursor, option: 'piece', value: result[0] });
              setFileOption({ id: cursor, option: 'editImage', value: img });
            }
          }),
        ];
      case captureState === Steps.ReviewItems:
        return [
          getButtonConfig(
            'Continue Receiving',
            'inherit',
            setCaptureState,
            !currentSessionProps.isNew &&
              currentSessionProps.receiveSession &&
              currentSessionProps.receiveSession.isClosed,
            { captureDropdown: true },
          ),
          getButtonConfig('Finish', 'primary', () => {
            const isFinished = onClose();
            if (isFinished) {
              navigate({
                pathname: mailroomRoutes.items,
                search: createSearchParams({
                  PieceStatus: PieceStatus.Unsent.toString(),
                }).toString(),
              });
            }
          }),
        ];
      case captureState === Steps.Images:
        return uploadImagesRef.current
          ? [
              getButtonConfig('Remove all', 'inherit', uploadImagesRef.current.removeAll),
              getButtonConfig('Upload Images', 'primary', uploadImagesRef.current.upload, uploadError),
            ]
          : [];
      default:
        return [];
    }
  };

  const getIconsByState = (): IconConfig[] => {
    switch (true) {
      case captureState === Steps.Edit: {
        const isUserLoading =
          !!currentFile?.piece &&
          (currentFile.isUpdating || !(currentFile?.piece?.recipient || currentFile?.pieceDetection));

        const icons = [
          getIconConfig(PersonPinRounded, 'PersonPinRounded', isUserLoading, () => setDrawer(DrawerTypes.User), {
            color: isUserLoading
              ? undefined
              : getUserColorPieceStatus(currentFile?.piece, currentFile?.pieceDetection?.status),
            disabled: !currentFile?.piece,
            tooltip: getUserIconTooltip(isUserLoading),
          }),
          getIconConfig(AddCommentRounded, 'AddCommentRounded', false, () => setDrawer(DrawerTypes.Comments), {
            disabled: !currentFile?.piece,
          }),
        ];

        if (currentSessionProps.params && currentSessionProps.params?.pieceType === PieceType.Mail) {
          icons.push(
            getIconConfig(
              CropRotateRounded,
              'CropRotateRounded',
              settings.AutoCroppingEnable &&
                currentSessionProps.params?.pieceType === PieceType.Mail &&
                !!currentFile?.piece &&
                !currentFile.isAutoCroppedCompleted,
              () => handleCrop(Steps.Edit),
              { disabled: !currentFile?.piece },
            ),
          );
        }

        if (
          currentSessionProps.receiveSession?.workflow &&
          ![Workflow.HighDensityMail, Workflow.MailBox, Workflow.Lockers].includes(
            currentSessionProps.receiveSession?.workflow,
          )
        ) {
          const location = getIconConfig(
            LocationOnRounded,
            'LocationOnRounded',
            false,
            () => setDrawer(DrawerTypes.Location),
            {
              disabled: !currentFile?.piece,
            },
          );
          icons.splice(1, 0, location);
        }

        if (currentSessionProps.params?.pieceType === PieceType.Package) {
          const isLoadingBarcode = isBarcodeLoading(
            currentFile?.piece,
            currentFile?.pieceAnnotations,
            currentFile?.barcodeDetected,
          );

          const barcode = getIconConfig(
            BarcodeIcon,
            'BarcodeIcon',
            isLoadingBarcode,
            () => setDrawer(DrawerTypes.Barcode),
            {
              color: isLoadingBarcode
                ? undefined
                : getBarcodeIconColor(currentFile?.pieceAnnotations || [], options.workflow),
              disabled: !currentFile?.piece,
              tooltip: tooltips.Barcode,
            },
          );
          icons.splice(1, 0, barcode);
        }

        return icons;
      }
      case captureState === Steps.Crop:
        return [getIconConfig(Rotate90DegreesCcwRounded, 'Rotate90DegreesCcwRounded', false, handleRotate)];
      case captureState === Steps.ReviewItems: {
        const isUploading = isUploadingPieces();
        const failedPieces = Object.values(files).filter((item) => item.status === 'failed');

        return failedPieces.length || isUploading
          ? [
              {
                ...getIconConfig(CloudUploadRounded, 'CloudUploadRounded', isUploading, retryFailedPieces),
                retryContent: failedPieces.length,
              },
            ]
          : [];
      }
      default:
        return [];
    }
  };

  const getTitleByState = () => {
    switch (captureState) {
      case Steps.ReceiveConfig:
        return 'Receive Items';
      case Steps.Photo:
        return 'Capture';
      case Steps.Review:
        return 'Review';
      case Steps.Edit:
      case Steps.Crop:
        return 'Edit';
      case Steps.Images:
        return 'Upload Images';
      case Steps.ReviewItems:
      case Steps.NotFound:
        return 'Review Items';
      default:
        return '';
    }
  };

  const getComponentByState = (): ComponentByState => {
    switch (captureState) {
      case Steps.Loading:
        return {
          Component: Loading,
          props: {
            percent: Math.round(
              (100 / (currentSessionProps.receiveSession?.totalPiecesCount || 1)) * Object.keys(files).length,
            ),
          },
        };
      case Steps.ReceiveConfig:
        return {
          Component: ReceiveConfig,
          props: {
            options,
            isLoading,
            updateReceiveConfig,
            setIsLoading,
            paperProps: { sx: { backgroundColor: 'background.default' } },
          },
        };
      case Steps.Photo:
        return {
          Component: CameraCapture,
          props: {
            capture: handlePhotoClick,
          },
        };
      case Steps.Review:
        return {
          Component: ImageEditor,
          props: { imageBase64: currentFile?.editImage || currentFile?.photo, croppingActive: false },
        };
      case Steps.Edit:
        return {
          Component: ImageEditor,
          props: {
            imageBase64:
              currentFile?.editImage ||
              getPieceAssetUrl(currentFile?.piece?.pieceAssets || [], PieceAssetSize.Large) ||
              currentFile?.photo,
            cropperRef: cropperRef,
            isLoading: !currentFile?.piece,
            onAnnotationClick: () => setDrawer(DrawerTypes.Barcode),
            annotations: {
              workflow: currentSessionProps.params?.workflow,
              items: currentFile?.pieceAnnotations,
            },
            recipient: currentFile?.piece?.recipient,
          },
        };
      case Steps.Crop:
        return {
          Component: ImageEditor,
          props: {
            imageBase64:
              currentFile?.editImage ||
              getPieceAssetUrl(currentFile?.piece?.pieceAssets || [], PieceAssetSize.Large, PieceAssetType.Original),
            croppingActive: true,
            cropperRef: cropperRef,
            isLoading: !getPieceAssetUrl(
              currentFile?.piece?.pieceAssets || [],
              PieceAssetSize.Large,
              PieceAssetType.Cropped,
            ),
          },
        };
      case Steps.ReviewItems:
        return {
          Component: ReviewItems,
          props: {
            mapCapturePiece: files,
            setDrawer: setDrawer,
            onCropClick: handleCrop,
            setCursor: setCursor,
            retryFailedPieces: retryFailedPieces,
            defaultCursor: Steps.ReviewItems === cropReturnPath && cursor,
            currentFile,
            requestedReceiveSession: currentSessionProps,
          },
        };
      case Steps.Images:
        return {
          Component: UploadImages,
          props: {
            ref: uploadImagesRef,
            type: options.pieceType,
            forceUpdate,
            onUpload,
            setInvalid: (valid: boolean) => dispatch({ type: ActionTypes.SET_UPLOAD_ERROR, payload: valid }),
          },
        };
      case Steps.NotFound:
        return {
          Component: NotFound,
          props: { onStartReceive: handleErrorStartReceive },
        };
      default:
        return { Component: null, props: {} };
    }
  };

  const getDrawerContent = (): DrawerComponent => {
    switch (drawer) {
      case DrawerTypes.User:
        return {
          Component: AssigmentList,
          props: { file: currentFile, onClose: closeDrawer, addEvent },
        };
      case DrawerTypes.Location:
        return currentFile?.piece?.pieceLocation
          ? {
              Component: LocationID,
              props: {
                piece: currentFile?.piece,
                onChange: (piece: Piece) => setFileOption({ id: piece.id, option: 'piece', value: piece }),
                onClose: closeDrawer,
              },
            }
          : { Component: null, props: { onClose: closeDrawer } };
      case DrawerTypes.Comments:
        return {
          Component: Comments,
          props: { pieceId: currentFile?.piece?.id, onClose: closeDrawer },
        };
      case DrawerTypes.Barcode:
        return {
          Component: Barcode,
          props: {
            onClose: closeDrawer,
            piece: currentFile?.piece,
            pieceAnnotations: currentFile?.pieceAnnotations || [],
            workflow: currentSessionProps.params?.workflow,
            detected: currentFile?.barcodeDetected,
            onCreate: (annotation: PieceAnnotation) =>
              dispatch({
                type: ActionTypes.MERGE_PIECE_BARCODES,
                payload: [annotation],
              }),
          },
        };
      default:
        return { Component: null, props: { onClose: closeDrawer } };
    }
  };

  return {
    captureState,
    getIconsByState,
    getComponentByState,
    onNextState,
    getButtonsByState,
    getTitleByState,
    getDrawerContent,
    setDrawer,
    isUnresolvedPieces,
    isUploadingPieces,
  };
};
