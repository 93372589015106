import { useRef } from 'react';

import { AnnotationType, Piece, PiecePagedList, Workflow } from '@apiContract';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
// eslint-disable-next-line import/named
import { times } from 'lodash/fp';
import { PromiseQueue } from 'src/utils/promiseQueue';

import { getCompanyFromHost } from '@common/utils/url';

import { CurrentSessionProps } from '../types';
import { ActionTypes, Actions } from './reducer';

const ITEMS_PER_PAGE = 100;

export const useReceiveSessionItems = (
  sessionProps: CurrentSessionProps,
  setItems: (items: Piece[]) => void,
  dispatch: React.Dispatch<Actions>,
) => {
  const { accessToken } = useOidcAccessToken();

  const loaded = useRef(false);

  const onPiecesLoaded = () => {
    if (
      sessionProps.receiveSession &&
      [Workflow.Lockers, Workflow.Carrier, Workflow.Location].includes(sessionProps.receiveSession.workflow)
    ) {
      const url = new URL(`${import.meta.env.VITE_APP_API}/api/piece/annotations/filtered`);
      const params = {
        ReceiveSessionId: sessionProps.receiveSession?.id.toString(),
        AnnotationType: AnnotationType.TrackingNumber.toString(),
      };
      url.search = new URLSearchParams(params).toString();
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'X-Company-Subdomain': getCompanyFromHost(),
        },
      }).then(async (response) => {
        const { items } = (await response.json()).objectData;
        dispatch({ type: ActionTypes.SET_PIECE_BARCODES, payload: items });
      });
    }
  };

  const loadPiecese = async () => {
    loaded.current = true;

    if (!sessionProps || !sessionProps.receiveSession || !sessionProps.receiveSession.id) return;
    const { totalPiecesCount, id } = sessionProps.receiveSession;
    const url = new URL(`${import.meta.env.VITE_APP_API}/api/Piece/filtered`);
    const params = { ReceiveSessionId: id, ItemsPerPage: `${ITEMS_PER_PAGE}`, pageNumber: '0' };
    const pages = totalPiecesCount ? Math.ceil(totalPiecesCount / ITEMS_PER_PAGE) : 0;

    const tasks: (() => Promise<undefined>)[] = [];

    times(
      (i) =>
        tasks.push(async () => {
          params.pageNumber = `${i}`;
          url.search = new URLSearchParams(params).toString();
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
              'X-Company-Subdomain': getCompanyFromHost(),
            },
          });
          const responseItems = (await response.json()).objectData as PiecePagedList;
          setItems(responseItems.items || []);
        }),
      pages,
    );

    const queue = new PromiseQueue(tasks, onPiecesLoaded);
    queue.run();
  };

  if (
    sessionProps &&
    !sessionProps.isNew &&
    sessionProps.receiveSession &&
    sessionProps.receiveSession.totalPiecesCount &&
    !loaded.current
  ) {
    loadPiecese();
  }
};
