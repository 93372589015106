import AddCommentRounded from '@mui/icons-material/AddCommentRounded';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Button, Grid } from '@mui/material';
import Autorenew from '@mui/icons-material/Autorenew';
import CropRotateRounded from '@mui/icons-material/CropRotateRounded';
import LocationOnRounded from '@mui/icons-material/LocationOnRounded';
import PersonPinRounded from '@mui/icons-material/PersonPinRounded';
import { FC } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import {
  PieceAction,
  PieceAssetSize,
  PieceAssetType,
  PieceType,
  RecipientAutoDetectionStatus,
  Workflow,
} from '@apiContract';
// eslint-disable-next-line import/no-named-as-default
import Lightbox, { useLightBox } from '@minimalsComponents/lightbox';
import { ImagePreview } from 'src/common/uiKit/ImagePreview';
import { getBarcodeIconColor } from 'src/common/utils/annotations';
import { getPieceAssetUrl } from 'src/utils/piece';

import RecipientNotification from '@common/components/RecipientNotification';
import { useSettings } from '@common/hooks/useSettings';

import { BarcodeIcon } from '@src/assets/icons/BarcodeIcon';

import {
  getPieceDetection,
  getUserColorPieceStatus,
  getUserIconTooltip,
  isBarcodeLoading,
  tooltips,
} from '../../../helper';
import { CapturePiece, CurrentSessionProps, DrawerTypes, IdV4, Steps } from '../../../types';
import IconWithLoader from '../../IconWithLoader';
import {
  sxBackBtn,
  sxGridChild_2_1,
  sxGridChild_2_1_1,
  sxGridChild_2_1_1_2,
  sxGridChild_2_1_2,
  sxImageContainer,
  sxMR0,
  sxPX2,
} from '../styles';

type PieceViewProps = {
  handleBack: () => void;
  capturePiece: CapturePiece;
  setDrawer: (type: DrawerTypes) => void;
  onCropClick: (returnPath: Steps) => void;
  retryFailedPieces: (id: IdV4) => void;
  currentSession: CurrentSessionProps;
};

const PieceView: FC<PieceViewProps> = ({
  handleBack,
  capturePiece,
  setDrawer,
  onCropClick,
  retryFailedPieces,
  currentSession,
}) => {
  const pieceDetection = getPieceDetection(capturePiece);
  const settings: Record<string, boolean> = useSettings(['AutoCroppingEnable']);
  const { piece } = capturePiece;

  const { workflow, pieceType } = currentSession?.params || {};

  const setDrawerIfPieceExists = (drawerType: DrawerTypes) => {
    if (piece) {
      setDrawer(drawerType);
    }
  };

  const pieceSrc =
    capturePiece.editImage ||
    getPieceAssetUrl(piece?.pieceAssets || [], PieceAssetSize.Large) ||
    (capturePiece.photo instanceof File ? URL.createObjectURL(capturePiece.photo) : capturePiece.photo);

  const lightbox = useLightBox([{ src: pieceSrc || '' }]);

  const isSend = (capturePiece?.piece?.lifecycleStage || 0) >= PieceAction.Submit;

  const getActiveButton = () => {
    if (capturePiece.status === 'failed') {
      return (
        <Button
          onClick={() => {
            retryFailedPieces(capturePiece.id as IdV4);
          }}
          variant="soft"
          sx={sxPX2}
          startIcon={<Autorenew />}
        >
          Retry
        </Button>
      );
    }

    const isUserLoading =
      !!piece &&
      (capturePiece.isUpdating ||
        !(piece.recipient || capturePiece.pieceDetection || capturePiece.status === 'loadedFromServer'));

    return (
      <>
        <IconWithLoader
          Icon={PersonPinRounded}
          tooltip={getUserIconTooltip(isUserLoading)}
          isLoading={isUserLoading}
          disabled={!piece}
          onClick={() => setDrawerIfPieceExists(DrawerTypes.User)}
          color={isUserLoading ? undefined : getUserColorPieceStatus(piece, pieceDetection?.status)}
          sx={sxMR0}
        />
        {PieceType.Package === pieceType ? (
          <IconWithLoader
            Icon={BarcodeIcon}
            tooltip={tooltips.Barcode}
            disabled={!piece}
            isLoading={isBarcodeLoading(
              capturePiece?.piece,
              capturePiece?.pieceAnnotations,
              capturePiece?.barcodeDetected,
            )}
            onClick={() => setDrawerIfPieceExists(DrawerTypes.Barcode)}
            color={
              !!capturePiece?.piece && !capturePiece?.pieceAnnotations
                ? undefined
                : getBarcodeIconColor(capturePiece.pieceAnnotations || [], workflow)
            }
            sx={sxMR0}
          />
        ) : null}

        {workflow && ![Workflow.HighDensityMail, Workflow.MailBox, Workflow.Lockers].includes(workflow) ? (
          <IconWithLoader
            Icon={LocationOnRounded}
            tooltip={tooltips.LocationOnRounded}
            disabled={!piece}
            onClick={() => setDrawerIfPieceExists(DrawerTypes.Location)}
            sx={sxMR0}
          />
        ) : null}

        <IconWithLoader
          Icon={AddCommentRounded}
          tooltip={tooltips.AddCommentRounded}
          disabled={!piece}
          onClick={() => setDrawerIfPieceExists(DrawerTypes.Comments)}
          sx={sxMR0}
        />
        {workflow && ![Workflow.Lockers, Workflow.Carrier, Workflow.Location].includes(workflow) && (
          <IconWithLoader
            Icon={CropRotateRounded}
            tooltip={tooltips.CropRotateRounded}
            disabled={!piece}
            isLoading={
              settings.AutoCroppingEnable &&
              !!piece &&
              !(
                capturePiece.isAutoCroppedCompleted ||
                piece?.pieceAssets.some(({ pieceAssetType }) => pieceAssetType === PieceAssetType.AutoCropped)
              )
            }
            onClick={() => onCropClick(Steps.ReviewItems)}
            sx={sxMR0}
          />
        )}
      </>
    );
  };

  const recipient =
    capturePiece.piece?.recipient ||
    (pieceDetection?.status === RecipientAutoDetectionStatus.IdentifiedVerificationRequired &&
      pieceDetection?.recipient);

  return (
    <Grid container item sx={sxGridChild_2_1}>
      <Grid
        container
        item
        justifyContent="flex-end"
        direction={{ xs: 'column-reverse', md: 'row' }}
        sx={sxGridChild_2_1_1}
      >
        <Grid item sx={sxGridChild_2_1_1_2}>
          <Box display="flex" justifyContent="space-between" height={1} alignItems="center">
            <Button onClick={handleBack} variant="text" startIcon={<ArrowBackIosNewIcon />} sx={sxBackBtn}>
              Back
            </Button>
            <Box display="flex" gap={1}>
              {!isSend && getActiveButton()}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid item flex={1} sx={sxGridChild_2_1_2}>
        <Box display="flex" sx={sxImageContainer}>
          <AutoSizer>
            {({ height, width }) => (
              <ImagePreview
                key={pieceSrc}
                imageDataUrl={pieceSrc}
                height={height}
                width={width}
                onAnnotationClick={() => setDrawer(DrawerTypes.Barcode)}
                annotations={{
                  workflow: currentSession.receiveSession?.workflow,
                  items: capturePiece.pieceAnnotations,
                }}
              />
            )}
          </AutoSizer>

          {recipient && <RecipientNotification recipient={recipient} />}
        </Box>
        <Lightbox
          disabledSlideshow
          disabledTotal
          disabledThumbnails
          disabledCaptions
          render={{
            buttonPrev: () => null,
            buttonNext: () => null,
          }}
          slides={[{ src: pieceSrc || '' }]}
          open={lightbox.open}
          close={lightbox.onClose}
          zoom={{
            maxZoomPixelRatio: 2.5,
            zoomInMultiplier: 1.5,
            doubleTapDelay: 300,
            doubleClickDelay: 300,
            doubleClickMaxStops: 2,
            keyboardMoveDistance: 50,
            wheelZoomDistanceFactor: 100,
            pinchZoomDistanceFactor: 100,
            scrollToZoom: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PieceView;
