import { Theme } from '@mui/material/styles';

const containerShared = (theme: Theme) => ({
  minHeight: '64px',
  width: '100%',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(1.5),
  zIndex: 1500,
  borderBottom: `1px solid ${theme.palette.grey[800]}`,
});

export const styles = (theme: Theme, buttonsCount: number) => ({
  container: {
    ...containerShared(theme),
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconBox: { display: 'flex', justifyContent: 'flex-start' },
  icon: { fontSize: '30px', color: 'white' },
  title: { textAlign: 'center', justifySelf: 'center' },
  mobileDesktopButton: {
    display: { xs: 'none', md: 'block' },
    backgroundColor: 'inherit',
  },
  groupButton: {
    display: 'flex',
    width: 1,
    justifyContent: { xs: 'space-between', md: 'flex-end' },
    gap: 1.5,
  },
  buttonWidth: { width: buttonsCount === 1 ? '100%' : '49%' },
});
