import type {
  Address,
  AddressArrayResponse,
  AddressRequest,
  CreateProfileRequest,
  CreateUserRequest,
  UpdateUserRequest,
  User,
  UserArrayResponse,
  UserPagedList,
  UserPagedListResponse,
  UserResponse,
  UserSetCompanyUpdateParams,
} from '@apiContract';
import { createSelector } from '@reduxjs/toolkit';

import { rdBackSignalr } from '@common/api/signalr';
import { getCompanyFromHost, getHostWithPrefix } from '@common/utils/url';

import { receivedDigitalApi } from './index';

export interface GetUsersFilteredRequest {
  UserRole: Array<string>;
  ItemsPerPage?: number;
  PageNumber?: number;
  SearchText?: string;
  Descending?: boolean;
  SortBy?: string;
}

const userApi = receivedDigitalApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyProfile: builder.query<User, void>({
      query: () => 'User/Profile',
      providesTags: (result) => (result ? [{ type: 'User', id: result.id }] : ['User']),
      transformResponse: (response: UserResponse) => {
        const data = response.objectData;
        if (data?.company?.subdomain && data.company.subdomain !== getCompanyFromHost()) {
          sessionStorage.removeItem('oidc.default');
          window.location.href = getHostWithPrefix(data.company.subdomain);
        }
        return response.objectData;
      },
      onCacheEntryAdded: (arg, { updateCachedData }) => {
        rdBackSignalr.registerEvent('OnUserProfileUpdated', 'getMyProfile', (notification: User) => {
          updateCachedData((draft) => {
            draft = notification;
            return draft;
          });
        });
      },
    }),

    createMyProfile: builder.mutation<User, CreateProfileRequest>({
      query: (requestData) => ({
        url: 'User/Profile',
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['User']),
      transformResponse: (response: UserResponse) => response.objectData,
    }),

    createUser: builder.mutation<User, CreateUserRequest>({
      query: (requestData) => ({
        url: 'User',
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: ['Users'],
      transformResponse: (response: UserResponse) => response.objectData,
    }),

    updateUser: builder.mutation<User, UpdateUserRequest>({
      query: (requestData) => ({
        url: 'User',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'User', id: result.id }] : []),
      transformResponse: (response: UserResponse) => response.objectData,
    }),

    getUsers: builder.query<User[], void>({
      query: () => 'User',
      providesTags: (result) =>
        result ? [...result.map((user) => ({ type: 'User' as const, id: user.id })), 'Users'] : ['Users'],
      transformResponse: (response: UserArrayResponse) => response.objectData,
    }),

    getUsersFiltered: builder.query<UserPagedList, GetUsersFilteredRequest>({
      query: (requestParams) => ({
        url: 'User/filtered',
        params: requestParams,
      }),
      providesTags: (result) =>
        result ? [...(result.items || []).map((user) => ({ type: 'User' as const, id: user.id })), 'Users'] : ['Users'],
      transformResponse: (response: UserPagedListResponse) => response.objectData,
    }),

    updateUsers: builder.mutation<User[], UpdateUserRequest[]>({
      query: (requestData) => ({
        url: 'User/list',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: (result) => result?.map((user) => ({ type: 'User', id: user.id })) || [],
      transformResponse: (response: UserArrayResponse) => response.objectData,
    }),

    createUserAddress: builder.mutation<Address[], AddressRequest & { userId: string }>({
      query: ({ userId, ...requestData }) => ({
        url: `address/user/${userId}`,
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: (result, _, requestData) => (result ? [{ type: 'User', id: requestData.userId }] : []),
      transformResponse: (response: AddressArrayResponse) => response.objectData,
    }),

    updateUserAddress: builder.mutation<Address[], AddressRequest & { userId: string }>({
      query: ({ userId, ...requestData }) => ({
        url: `address/user/${userId}`,
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: (result, _, requestData) => (result ? [{ type: 'User', id: requestData.userId }] : []),
      transformResponse: (response: AddressArrayResponse) => response.objectData,
    }),

    deleteUserAddress: builder.mutation<Address[], { addressId: number; userId: string }>({
      query: ({ userId, addressId }) => ({
        url: `address/user/${userId}/${addressId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _, requestData) => (result ? [{ type: 'User', id: requestData.userId }] : []),
      transformResponse: (response: AddressArrayResponse) => response.objectData,
    }),
    setCompanyUser: builder.mutation<object, UserSetCompanyUpdateParams>({
      query: (params) => ({
        url: 'User/set-company',
        method: 'PUT',
        params,
      }),
    }),
  }),
});

export const {
  useGetMyProfileQuery,
  useLazyGetMyProfileQuery,
  useCreateMyProfileMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetUsersQuery,
  useGetUsersFilteredQuery,
  useUpdateUsersMutation,
  useCreateUserAddressMutation,
  useUpdateUserAddressMutation,
  useDeleteUserAddressMutation,
  useSetCompanyUserMutation,
} = userApi;

export const selectUserById = createSelector(
  (users: User[] | undefined) => users,
  (_: User[] | undefined, id: string | null) => id,
  (users, id) => users?.find((user) => user.id === id) || null,
);

export const selectUsersByIds = createSelector(
  (users: User[] | undefined) => users,
  (_: User[] | undefined, ids: string[]) => ids,
  (users, ids) => users?.filter((user) => ids.includes(user.id)) || [],
);

export const selectActiveUsers = createSelector(
  (users: User[] | undefined) => users,
  (users) => users?.filter((user) => user.active) || [],
);

export const selectActiveRecipients = createSelector([selectActiveUsers], (activeUsers) =>
  activeUsers.filter((user) => user.roles.includes('Recipient')),
);
